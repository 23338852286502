import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { cloneDeep, filter, findIndex, first, get, intersection, isEqual, last, map, remove } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Button, UncontrolledTooltip } from 'reactstrap'
import hash from 'object-hash'

import { draggingStatus, expandableList, listExpanded, ListHeaderTracker, nicelyOrderList, recursivelyOrderItems, removeRecursiveItems, typeIdMapping, updateListItem } from '../../helpers/list'
import { ListDetailFragment, ListDetailItemsFragment, ListMemberIdType, ReportsListFragment, ReportsListMemberFragment } from '../../__generated__/graphql'
import { FormInput } from '../ui/Forms/FormInput'
import MiddleEllipsis from '../Shared/MiddleEllipsis'
import { CombinedListExpanded, recursivelyOrderReport } from '../../helpers/report'
import { ListSidebarToolbar, SidebarInsert, SidebarItem } from '../Report/Shared/ReportSidebar'
import { ReportDisplayType } from '../Report/Shared/ReportComponent'

interface ListDetailSidebarProps {
  tree: (CombinedListExpanded)[]
  editMode: boolean
  list?: ReportsListFragment | ListDetailFragment
  setEditedList?: React.Dispatch<React.SetStateAction<ReportsListFragment | ListDetailFragment | undefined>>
  listHeaders: ListHeaderTracker[]
  setListHeaders: React.Dispatch<React.SetStateAction<ListHeaderTracker[]>>
  selectedItems: string[]
  setSelectedItems: React.Dispatch<React.SetStateAction<string[]>>
  dragging: draggingStatus
}

const Circle = ({ color, percentage }:{color: string, percentage?: number}) => {
  const r = 7;
  const circ = 2 * Math.PI * r;
  const strokePct = ((100 - (percentage || 0)) * circ) / 100; // where stroke will start, e.g. from 15% to 100%.
  return (
    <circle
      r={r}
      cx={10}
      cy={10}
      fill="transparent"
      stroke={strokePct !== circ ? color : ""} // remove color as 0% sets full circumference
      strokeWidth={".2rem"}
      strokeDasharray={circ}
      strokeDashoffset={percentage ? strokePct : 0}
    ></circle>
  );
};

const ListDetailSidebar: React.FC<ListDetailSidebarProps> = ({ tree, list, editMode, setEditedList, listHeaders, setListHeaders,selectedItems, setSelectedItems, dragging }) => {
  const [openItems, setOpenItems] = useState<(number | string)[]>([])
  const [expanded, setExpanded] = useState(true)
  const [highlighted, setHighlighted] = useState<string[]>([])
  const [editingHeader, setEditingHeader] = useState<string | undefined>(undefined)
  let allOpenableItems = expandableList(tree)
  const allExpanded = isEqual(intersection(allOpenableItems, openItems), allOpenableItems)
  const [shownItems, setShownItems] = useState<(number | string)[] | undefined>(undefined)
  const [orderedItems, openOrderedItems] = useMemo(() => {
    return [recursivelyOrderReport(tree), recursivelyOrderReport(tree, openItems)]
  }, [tree, openItems])
  let latestGroupId = 1
  let latestBaseOrder = 0
  const allListItemsCount = filter(list?.items, (item: ReportsListMemberFragment | ListDetailItemsFragment) => {
    if(item.group === 1 && typeof item.order === "number" && item.order > latestBaseOrder){
      latestBaseOrder = item.order
    }
    if(item.item){
      if(item.item?.__typename === "ListGroup"){
        if(item.item.id > latestGroupId){
          latestGroupId = item.item.id
        }
      } else {
        return true
      }
    }
    return false
  }).length

  useEffect(() => {
    setHighlighted([])
  }, [editMode])

  const orderedItemCount = orderedItems.length
  const listHash = hash({items: orderedItems?.map((item) => {return {group: item.group, order: item.order, uniqId: item.uniqId, subGroupId: item.subGroupId}}), selectedItems: selectedItems})
  // console.log(68, {listHash, selectedItems, orderedItems})
  // const handleItemCheckbox = (value:boolean, uniqId:string) => {
  //   setEditedList((editedList) => {
  //     if(!editedList) return
  //     let editedItems = cloneDeep(editedList.items) || []
  //     const [type, id] = uniqId.split(":")
  //     let editedItem = first(remove(editedItems, (item) => {
  //       return item.type === type && get(item, `item.${typeIdMapping[type]}`, "")?.toString() === id
  //     }))
  //     if(editedItem){
  //       if(value){
  //         const newGroupId = latestGroupId + 1
  //         // Create Group and replace item with group and assign
  //         const newGroup:ListDetailItemsFragment = {
  //           __typename: 'ListMember',
  //           order: editedItem.order,
  //           group: editedItem.group,
  //           type: ListMemberIdType.list_group,
  //           fromList: undefined,
  //           item: {
  //             __typename:"ListGroup",
  //             id: newGroupId,
  //           },
  //         }
  //         const newItem = {
  //           ...editedItem,
  //           order: 0,
  //           group: newGroupId
  //         }
  //         editedItems = [...editedItems, newGroup, newItem]
  //       } else {
  //         // Should this fail if it has sub-entities
  //         // Replace group with item in parent
  //         const oldGroup = first(remove(editedItems, (item) => {
  //           return item.type === ListMemberIdType.list_group && item.item?.__typename === "ListGroup" && item.item.id === editedItem?.group
  //         }))
  //         const newItem = {
  //           ...editedItem,
  //           order: oldGroup?.order,
  //           group: oldGroup?.group
  //         }
  //         editedItems = [...editedItems, newItem]
  //       }
  //       return {...list, items: nicelyOrderList(editedItems)}
  //     }
  //   })
  // }

  // const handleToEndButton = (direction: "top"|"bottom") => {
  //   if(!selectedItems?.length){
  //     return
  //   }
  //   setEditedList((editedList) => {
  //     if(!editedList) return
  //     const finalList = removeRecursiveItems(selectedItems, orderedItems, [])
  //     if(list.items && finalList.length > 0){
  //       let updatedItems = map(editedList.items, (item) => {
  //         const foundIndex = finalList.findIndex((uniqId, idx) => {
  //           const [type, id] = uniqId.split(":")
  //           return item.type === type && get(item, `item.${typeIdMapping[type]}`, "")?.toString() === id
  //         })
  //         if(foundIndex !== -1){
  //           const modifier = (foundIndex + 1)/(finalList.length + 2)
  //           const baseNumber = direction === "top" ? -1 : last(tree)?.order || (tree.length + 1)
  //           return {
  //             ...item,
  //             order: baseNumber + modifier,
  //             group: 1,
  //           }
  //         }
  //         return item
  //       })
  //       return {...list, items: nicelyOrderList(updatedItems)}
  //     }
  //   })
  // }

  // const handleNudgeButton = (direction: "up"|"down") => {
  //   if(selectedItems.length !== 1 || !list.items){
  //     return
  //   }
  //   setEditedList((editedList) => {
  //     if(!editedList?.items) return
  //     const selectedItem = selectedItems[0]
  //     const currentIndex = orderedItems.findIndex((listExpanded) => listExpanded?.uniqId === selectedItem)
  //     const currentItem = orderedItems[currentIndex]
  //     const checkItem = orderedItems[direction === "up" ? currentIndex -1 : currentIndex + (currentItem?.subGroup?.length || 0) + 1]
  //     const modifier = direction === "up" ? -0.5 : 0.5
  //     const currentUniqId = !!currentItem?.subGroupId ? `list_group:${currentItem?.subGroupId}` : currentItem?.uniqId
  //     const updatedItems = updateListItem(editedList.items, currentUniqId, (item)=>{
  //       if(!checkItem){
  //         return item
  //       }
  //       return {
  //         ...item,
  //         order: (checkItem.order || 0) + modifier,
  //         group: checkItem.group,
  //       }
  //     })
  //     return {...list, items: nicelyOrderList(updatedItems)}
  //   })
  // }


  // const addHeaderButton = () => {
  //   if(!list.items){
  //     return
  //   }
  //   setEditedList((editedList) => {
  //     const newHeaderId = listHeaders.reduce((value, header) => header.id < value ? header.id : value , 0) - 1
  //     const defaultValue = ""
  //     const newHeader:ListDetailItemsFragment = {
  //       __typename: "ListMember",
  //       order: -0.5,
  //       group: 1,
  //       type: ListMemberIdType.list_header,
  //       item: {
  //         __typename: "ListHeader",
  //         id: newHeaderId,
  //         text: defaultValue
  //       }
  //     }
  //     setEditingHeader(newHeaderId)
  //     setListHeaders([...listHeaders, {id: newHeaderId, status: "created", value: defaultValue}])
  //     if(!editedList?.items) return
  //     return {...list, items: nicelyOrderList([...editedList.items, newHeader])}
  //   })
  // }
  return (
    <div className={classNames("list-table-container list-sidebar report-sidebar mb-2 d-flex flex-direction-column", {"list-sidebar-expanded": expanded, "list-sidebar-collapsed": !expanded})} >
      {!expanded &&
        <div className="list-table-header d-flex align-items-center justify-content-between background-white cursor-pointer" onClick={() => setExpanded(!expanded)}>
          <h4 className="pl-3 mb-0">
            Order
          </h4>
          <div className="pr-3">
            <FontAwesomeIcon
              icon="plus"
            />
          </div>
        </div>
      }
      {expanded &&
        <>
          <div className="list-table-header d-flex align-items-center justify-content-between background-white cursor-pointer" onClick={() => setExpanded(!expanded)}>
            <h3 className="pl-3 mb-0 font-weight-500">Order</h3>
            <div>
              <svg width={20} height={20}>
                <g transform={`rotate(-90 ${"10 10"})`}>
                  <Circle color="#DBDBDB" />
                  <Circle color={"#044F76"} percentage={Math.round((orderedItemCount * 100)/allListItemsCount)} />
                </g>
              </svg>
              {allListItemsCount - orderedItemCount} Unordered
            </div>
            <div className="pr-3">
              <FontAwesomeIcon
                icon="minus"
              />
            </div>
          </div>
          {editMode && setEditedList &&
            <ListSidebarToolbar
              selectedItems={highlighted}
              setEditedList={setEditedList}
              orderedItems={orderedItems}
              listType={"list"}
              tree={tree}
              setEditingHeader={setEditingHeader}
              openItems={openOrderedItems}
            />
          }
          {/* <div className="fake-link list-sidebar-expand-link" onClick={() => allExpanded ? setOpenItems([]) : setOpenItems(allOpenableItems) }>{allExpanded ? "Collapse All" : "Expand All"}</div> */}
          <div className={classNames("report-sidebar-container position-relative d-flex flex-column h-100 overflow-y-auto overflow-x-hidden")}>
            { dragging === draggingStatus.Dragging &&
              <div className="list-sidebar-table-drop">
                <FontAwesomeIcon
                  icon="arrow-to-bottom"
                  className="mb-2"
                />
                <br />
                Drop Items
                <br />
                Here
              </div>
            }
            <DndProvider backend={HTML5Backend}>
              {/* {tree.map((listItem: listExpanded, idx) => {
                return(
                  <SidebarItem
                    key={listHash+listItem.uniqId}
                    list={listItem}
                    openItems={openItems}
                    currentHierarchy={[]}
                    setOpenItems={setOpenItems}
                    editMode={editMode}
                    handleItemCheckbox={handleItemCheckbox}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                    orderedItems={orderedItems}
                    editedList={list}
                    setEditedList={setEditedList}
                    listHash={listHash}
                    listHeaders={listHeaders}
                    setListHeaders={setListHeaders}
                    editingHeader={editingHeader}
                    setEditingHeader={setEditingHeader}
                  />
                )
              })}
              <SidebarBottom
                key={listHash}
                selectedItems={selectedItems}
                setEditedList={setEditedList}
                orderedItems={orderedItems}
              /> */}
              {tree.map((listItem: (CombinedListExpanded), idx) => {
                return(
                  <SidebarItem
                    key={listItem.uniqId}
                    list={listItem}
                    setEditedList={setEditedList}
                    openItems={openItems}
                    shownItems={shownItems}
                    currentHierarchy={[]}
                    setOpenItems={setOpenItems}
                    view={ReportDisplayType.Live}
                    editMode={editMode}
                    highlighted={highlighted}
                    setHighlighted={setHighlighted}
                    orderedItems={orderedItems}
                    editingHeader={editingHeader}
                    setEditingHeader={setEditingHeader}
                    latestGroupId={latestGroupId}
                    listType={"list"}
                  />
                )
              })}
              <SidebarInsert
                setEditedList={setEditedList}
                highlighted={highlighted}
                currentHierarchy={[]}
                orderedItems={orderedItems}
                depth={0}
                group={1}
                order={latestBaseOrder+1}
              />
            </DndProvider>
          </div>
        </>
      }
    </div>
  )
}

// interface SidebarItemProps {
//   list: listExpanded
//   openItems: string[]
//   setOpenItems: (newOpenItems: string[]) => void
//   selectedItems: string[]
//   setSelectedItems: (newOpenItems: string[]) => void
//   currentHierarchy: string[]
//   orderedItems: listExpanded[]
//   handleItemCheckbox: (value:boolean, uniqId: string) => void
//   editMode: boolean
//   editedList: ListDetailFragment
//   listHash: string
//   setEditedList: React.Dispatch<React.SetStateAction<ListDetailFragment | undefined>>
//   listHeaders: ListHeaderTracker[]
//   setListHeaders: React.Dispatch<React.SetStateAction<ListHeaderTracker[]>>
//   editingHeader?: number
//   setEditingHeader: React.Dispatch<React.SetStateAction<number | undefined>>
// }

// interface dragProps {
//   uniqId: string
//   subGroupId?: number
// }

// export const SidebarItem: React.FC<SidebarItemProps> =({list, currentHierarchy, openItems, setOpenItems, editMode, handleItemCheckbox, selectedItems, setSelectedItems, orderedItems, editedList, setEditedList, listHeaders, setListHeaders, listHash, editingHeader, setEditingHeader}) => {
//   const hasSubgroup = !!list.subGroup
//   const [type] = list.uniqId.split(":")
//   const matchingHeader = listHeaders.find((header) => type === "list_header" && header.id === list.id)
//   const editText = type === "list_header" && list.id === editingHeader && editMode
//   const depth = currentHierarchy.length
//   const [{isDragging}, drag, preview] = useDrag(
//     () => ({
//       type: "listItem",
//       item: { uniqId: list.uniqId, subGroupId: list.subGroupId },
//       collect: (monitor) => {
//         return {
//           isDragging: monitor.isDragging()
//         }
//       },
//       canDrag: () => {
//         return editMode
//       },

//     }),
//     [list.uniqId, editMode],
//   )

//   const [{isOverTop, canDropTop}, dropTop] = useDrop(() => ({
//     accept: "listItem",
//     drop: (item:dragProps, monitor) => {
//       setEditedList((editedList)=> {
//         if(!editedList){
//           return editedList
//         }
//         let allItems = selectedItems.includes(item.uniqId) ? selectedItems : [item.uniqId]
//         const removeList = [list.uniqId, ...currentHierarchy]
//         const finalList = removeRecursiveItems(allItems, orderedItems, removeList)
//         // Update all remaining
//         if(editedList.items && finalList.length > 0){
//           let updatedItems = map(editedList.items, (item) => {
//             const foundIndex = finalList.findIndex((uniqId, idx) => {
//               const [type, id] = uniqId.split(":")
//               return item.type === type && get(item, `item.${typeIdMapping[type]}`, "")?.toString() === id
//             })
//             if(foundIndex !== -1){
//               return {
//                 ...item,
//                 order: (list.order || 0) - (finalList.length - foundIndex + 1)/(finalList.length + 2),
//                 group: list.group,
//               }
//             }
//             return item
//           })
//           return {...editedList, items: nicelyOrderList(updatedItems)}
//         }
//         return editedList
//       })
//     },
//     collect: (monitor) => {
//       return {
//         isOverTop: monitor.isOver(),
//         canDropTop: monitor.canDrop(),
//       }
//     }
//   }))

//   const [{isOverBottom, canDropBottom}, dropBottom] = useDrop(() => ({
//     accept: "listItem",
//     drop: (item:dragProps, monitor) => {
//       setEditedList((editedList)=> {
//         if(!editedList){
//           return editedList
//         }
//         let allItems = selectedItems.includes(item.uniqId) ? selectedItems : [item.uniqId]
//         const removeList = [list.uniqId, ...currentHierarchy]
//         const finalList = removeRecursiveItems(allItems, orderedItems, removeList)
//         // Update all remaining
//         if(editedList.items && finalList.length > 0){
//           let updatedItems = map(editedList.items, (item) => {
//             const foundIndex = finalList.findIndex((uniqId, idx) => {
//               const [type, id] = uniqId.split(":")
//               return item.type === type && get(item, `item.${typeIdMapping[type]}`, "")?.toString() === id
//             })
//             if(foundIndex !== -1){
//               const modifier = (foundIndex + 1)/(finalList.length + 2)
//               if(!hasSubgroup){
//                 return {
//                   ...item,
//                   order: (list.order || 0) + modifier,
//                   group: list.group,
//                 }
//               } else {
//                 return {
//                   ...item,
//                   order: (list.subGroup?.length || 0) > 0 ? (first(list.subGroup)?.order || 0) - (1 - modifier) : modifier ,
//                   group: list.subGroupId,
//                 }
//               }
//             }
//             return item
//           })
//           return {...editedList, items: nicelyOrderList(updatedItems)}
//         }
//         return editedList
//       })
//     },
//     collect: (monitor) => {
//       return {
//         isOverBottom: monitor.isOver(),
//         canDropBottom: monitor.canDrop(),
//       }
//     }
//   }))

//   const isOpen = openItems.includes(list.uniqId)
//   const handleExpand = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
//     e.stopPropagation()
//     if(isOpen){
//       let removedArray = cloneDeep(openItems)
//       remove(removedArray, (id) => list.uniqId === id)
//       setOpenItems(removedArray)
//     } else {
//       setOpenItems([...openItems, list.uniqId])
//     }
//   }
//   const isSelected = editMode && selectedItems.includes(list.uniqId)
//   const handleSelect = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
//     if(!editMode){
//       return
//     }
//     // type:any has to be used as target.closest is not recognised by typescript
//     // The reason this is required is that selecting reloads the components to update drag and drop
//     // functionality but this was causing the checkbox click to get eaten
//     const target:any = e.target
//     if(target.closest(".stop-select")){
//       return
//     }
//     e.stopPropagation()
//     if(isSelected){
//       if(e.ctrlKey || e.metaKey || e.shiftKey){
//         let removedArray = cloneDeep(selectedItems)
//         remove(removedArray, (id) => list.uniqId === id)
//         setSelectedItems(removedArray)
//       } else {
//         setSelectedItems([])
//       }
//     } else {
//       if(e.ctrlKey || e.metaKey){
//         setSelectedItems([...selectedItems, list.uniqId])
//       } else if (e.shiftKey){
//         if(selectedItems.length === 0){
//           setSelectedItems([list.uniqId])
//         } else if (selectedItems.length === 1){
//           const selectedOrder = findIndex(orderedItems, {uniqId: first(selectedItems)})
//           const currentOrder = findIndex(orderedItems, {uniqId: list.uniqId})
//           if(selectedOrder > currentOrder){
//             setSelectedItems(orderedItems.slice(currentOrder, selectedOrder + 1).map((item) => item.uniqId))
//           } else {
//             setSelectedItems(orderedItems.slice(selectedOrder, currentOrder + 1).map((item) => item.uniqId))
//           }
//         } else {
//           console.log("Shift click when multiple selected not implemented")
//           setSelectedItems([list.uniqId])
//         }
//       } else {
//         setSelectedItems([list.uniqId])
//       }
//     }
//   }
//   const sectionToggle = (value:boolean) => {
//     handleItemCheckbox(value, list.uniqId)
//   }
//   const handleNameChange = (value: string) => {
//     if(type !== "list_header" || !editedList.items){
//       return
//     }
//     const updatedItems = updateListItem(editedList.items, list.uniqId, (item)=>{
//       if(item.type !== ListMemberIdType.list_header){
//         return item
//       }
//       return {
//         ...item,
//         item: {
//           __typename: "ListHeader",
//           id: list.id as number,
//           text: value,
//         }
//       }
//     })
//     let clonedListHeaders = cloneDeep(listHeaders)
//     let removedListHeaders = remove(clonedListHeaders, (header) => header.id === list.id)
//     let listHeader:ListHeaderTracker
//     if(removedListHeaders.length === 1){
//       listHeader = removedListHeaders[0]
//       listHeader.value = value
//     } else {
//       listHeader = {
//         id: list.id as number,
//         status: "updated",
//         value: value
//       }
//     }
//     setListHeaders([...clonedListHeaders, listHeader])
//     setEditedList({...editedList, items: nicelyOrderList(updatedItems)})
//   }
//   const handleEnter = () => {
//     if(editText){
//       setEditingHeader(undefined)
//     }
//   }
//   const removeHeader = () => {
//     if(!editMode) return
//     if(type === "list_header"){
//       setListHeaders((listHeaders) => {
//         let cloneListHeaders = cloneDeep(listHeaders)
//         if(matchingHeader){
//           remove(cloneListHeaders, (header) => header.id === matchingHeader.id)
//         }
//         if(matchingHeader?.status === "created"){
//           return cloneListHeaders
//         } else {
//           return [...cloneListHeaders, {id: list.id as number, status: "deleted", value: list.name || ""}]
//         }
//       })
//       setEditedList((editedList) => {
//         let cloneItems = cloneDeep(editedList?.items) as ListDetailItemsFragment[]
//         if(cloneItems){
//           remove(cloneItems, (item) => item.type === ListMemberIdType.list_header && item.item?.__typename === "ListHeader" && item.item.id === list.id)
//         }
//         if(editedList){
//           return {...editedList, items: nicelyOrderList(cloneItems)}
//         }
//       })
//     } else {
//       setEditedList((editedList) => {
//         if(!editedList?.items) return
//         let updatedItems = cloneDeep(editedList.items)
//         if(list.subGroupId){
//           // If a group remove the entire group and then nicelyOrderLists will remove everything in the subgroup
//           updatedItems = updateListItem(updatedItems, `list_group:${list.subGroupId}`, (item)=>{
//             return undefined
//           })
//         }
//         updatedItems = updateListItem(updatedItems, list.uniqId, (item)=>{
//           return {
//             ...item,
//             order: null,
//             group: null,
//           }
//         })
//         return {...editedList, items: nicelyOrderList(updatedItems)}
//       })
//     }
//   }
//   const selectedCount = selectedItems.includes(list.uniqId) ? selectedItems.length : 1
//   const preUniqId = list.uniqId.replace(':', '-')
//   const uniqId = preUniqId.replace('^', 'caret')
//   return (
//     <>
//       <div ref={drag} className={classNames(`list-sidebar-item list-sidebar-depth-${depth} drop-target-hover`, {"has-subgroup": hasSubgroup, "is-selected": isSelected || isDragging, "drop-target-hover-top": isOverTop, "drop-target-hover-bottom": (isOverBottom && !hasSubgroup), "drop-target-into": (isOverBottom && hasSubgroup), "drag-is-dragging": isDragging, "is-header": type === "list_header", "is-editing": editMode})} onClick={handleSelect}>
//         <div ref={preview} className={classNames("list-sidebar-drag-preview")}>
//           <FontAwesomeIcon
//             icon="arrows-alt"
//             className="mr-2"
//           />
//           {selectedCount > 1 ? `${selectedCount} Items` : list.name}
//         </div>
//         {canDropTop &&
//           <div ref={dropTop} className="drop-target-top" style={{paddingLeft: (depth+1) * 15 + 5}}/>
//         }
//         {canDropBottom &&
//           <div ref={dropBottom} className="drop-target-bottom" style={{paddingLeft: (depth+1) * 15 + 5}}/>
//         }
//         <div style={{paddingLeft: depth * 15}} className="d-flex align-items-center">
//           <div className="list-sidebar-drag">
//             <FontAwesomeIcon
//                 icon={"grip-horizontal"}
//                 size="sm"
//               />
//           </div>
//           <div onClick={(e) => handleExpand(e)} className={`list-sidebar-caret`}>
//             {hasSubgroup &&
//               <FontAwesomeIcon
//                 icon={isOpen ? "caret-down" : "caret-right"}
//                 size="sm"
//               />
//             }
//           </div>
//           <div className="list-sidebar-title">
//             <div className="d-flex align-items-center">
//               {editText ?
//                 <FormInput
//                   property={"name"}
//                   displayName=""
//                   placeholder={"Header Name"}
//                   type={"text"}
//                   idx={list.uniqId}
//                   editMode={editText}
//                   propertyVal={list.name}
//                   updateValue={(value) => handleNameChange(value)}
//                   subClasses={{wrapperClasses: editText ? "stop-select flex-grow-1" : "flex-grow-1"}}
//                   autoFocus={editText}
//                   handleEnter={() => handleEnter()}
//                 /> :
//                 <>
//                   <div className="flex-grow-1 text-truncate" onClick={() => type === "list_header" && setEditingHeader(list.id as number)} id={"order-"+uniqId}>
//                     <MiddleEllipsis text={list.name} />
//                   </div>
//                   <UncontrolledTooltip placement="bottom" target={"order-"+uniqId}>
//                     {list.name}
//                   </UncontrolledTooltip>
//                   {type !== "list_header" &&
//                     <div className='text-gray-50 ml-1'>({list.id})</div>
//                   }
//                 </>}
//             </div>
//           </div>
//           <div className="d-flex hover-swap-icon hover-display-item list-sidebar-remove" onClick={() => removeHeader()}>
//             <FontAwesomeIcon icon="times-circle" className="on-hover"/>
//             <FontAwesomeIcon icon="times" className="off-hover"/>
//           </div>
//           {editMode &&
//             <div>
//               <FormInput
//                 property={"section"}
//                 idx={list.id}
//                 type={"checkbox"}
//                 subtype={"boolean"}
//                 editMode={editMode}
//                 propertyVal={hasSubgroup}
//                 updateValue={sectionToggle}
//                 subClasses={{wrapperClasses: "stop-select no-gutters", inputWrapperClasses: 'pl-0'}}
//               />
//             </div>
//             }
//         </div>
//       </div>
//       {isOpen && hasSubgroup && list.subGroup?.map((listItem: listExpanded, idx) => {
//         return(
//           <SidebarItem
//             key={selectedItems.length+listItem.uniqId}
//             list={listItem}
//             openItems={openItems}
//             currentHierarchy={[...currentHierarchy, list.uniqId]}
//             setOpenItems={setOpenItems}
//             editMode={editMode}
//             handleItemCheckbox={handleItemCheckbox}
//             selectedItems={selectedItems}
//             setSelectedItems={setSelectedItems}
//             orderedItems={orderedItems}
//             editedList={editedList}
//             setEditedList={setEditedList}
//             listHash={listHash}
//             listHeaders={listHeaders}
//             setListHeaders={setListHeaders}
//             editingHeader={editingHeader}
//             setEditingHeader={setEditingHeader}
//           />
//         )
//       })}
//     </>
//   )
// }

interface SidebarBottomProps {
  selectedItems: string[]
  orderedItems: listExpanded[]
  setEditedList: React.Dispatch<React.SetStateAction<ListDetailFragment | undefined>>
}

export const SidebarBottom: React.FC<SidebarBottomProps> =({selectedItems, orderedItems, setEditedList}) => {
  const [{isOver, canDrop}, drop] = useDrop(() => ({
    accept: "listItem",
    drop: (item:dragProps, monitor) => {
      setEditedList((editedList)=> {
        if(!editedList){
          return editedList
        }
        let allItems = selectedItems.includes(item.uniqId) ? selectedItems : [item.uniqId]
        const finalList = removeRecursiveItems(allItems, orderedItems, [])
        // Update all remaining
        if(editedList.items && finalList.length > 0){
          let updatedItems = map(editedList.items, (item) => {
            const foundIndex = finalList.findIndex((uniqId, idx) => {
              const [type, id] = uniqId.split(":")
              return item.type === type && get(item, `item.${typeIdMapping[type]}`, "")?.toString() === id
            })
            if(foundIndex !== -1){
              const modifier = (foundIndex + 1)/(finalList.length + 2)
              return {
                ...item,
                order: (orderedItems.length || 0) + modifier,
                group: 1,
              }
            }
            return item
          })
          return {...editedList, items: nicelyOrderList(updatedItems)}
        }
        return editedList
      })
    },
    collect: (monitor) => {
      return {
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }
    }
  }))
  return (
    <div className={classNames(`list-sidebar-bottom drop-target-hover`, {"drop-target-hover-top": isOver})}>
      {(canDrop || true) &&
        <div ref={drop} className="drop-target-top h-100"/>
      }
    </div>
  )
}

export default ListDetailSidebar