import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { History, Location } from 'history'
import { cloneDeep, compact, find, first, get, uniqBy } from 'lodash'
import React, { useContext, useRef, useState } from 'react'
import { match, Route, Switch, useParams } from 'react-router-dom'
import { Button, Col, Container, Form, FormGroup, Input, Row, UncontrolledAlert } from 'reactstrap'

import Auth from '../../Auth/Auth'
import { EditButtonContext } from '../../Context/EditButtonContext'
import { clientDocumentListColumnDef } from "../../helpers/columnDefs"
import { DocumentAccess, File as Document, FileFullDetailsFragment, FileTypeSubTypeMap, Maybe, PlanDocumentsFragment, useClientReportDocumentsQuery, useMeQuery, ReportDocumentDetailsFragment } from '../../__generated__/graphql'
import { AddFileModal, getDocumentPermission } from '../Shared/Document'
import SortableTable from "../Shared/SortableTable"
import PlaceHolder from '../ui/PlaceHolder'
import ClientDocumentDetail from './ClientDocumentDetail'
import { appDate } from '../../Context/CalendarContext'
import { DATE_API_FORMAT } from '../../helpers/constant'
import { ClientReportAsDocument } from './ClientReportDetail'


type idProps = {
  clientId: number
  planId?: number
  history: History
  location: Location
  auth: Auth
  match: match
}

export type documentTableData = Omit<FileFullDetailsFragment, "__typename"|"id"> & {
  id: number | string
  clientId: number
  planId?: number
  associations: string[]
  __typename?: "File" | "Report"
}

const ClientDocumentsList: React.FC<idProps> = ({ clientId, planId, history, location, auth, match }) => {
  const [addFileModalOpen, setAddFileModalOpen] = useState(false)

  const addFileInputRef = useRef<HTMLInputElement>(null)
  const [search, setSearch] = useState("")

  const { loading, data, error, refetch } = useClientReportDocumentsQuery({
    fetchPolicy: "cache-and-network",
    variables: { id: clientId }
  })
  const { resetErrors } = useContext(EditButtonContext)

  const { loading:userLoading, error:userError, data:userData } = useMeQuery({ fetchPolicy: "cache-first" })
  const user = userData?.me || undefined

  const selectDocument = (id:string) => {
    resetErrors()
    history.push(`/clients/${clientId}${!!planId ? `/${planId}` : ""}/documents/${id}`)
  }

  const openFileModal = () => {
    // setAddFileModalOpen(!addFileModalOpen)
    addFileInputRef?.current?.click()
  }

  const handleEnterKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.key === 'Enter' && e.preventDefault()
  }

  const heading = (
    <>
      <div className="pane pane-toolbar sticky-top above-picker">
        {auth.checkPermissions(["upload:documents"]) &&
          <Button color="light btn-thin" className="mr-1 text-callan-blue" onClick={openFileModal} disabled={!data}>
            Add Document
            <FontAwesomeIcon
              icon="plus-circle"
              className="ml-2 text-callan-blue"
            />
          </Button>
        }
        <Form className="mr-2 pr-3 border-right">
          <FormGroup row className="relative m-0 mr-1">
            <Input
              type="text"
              placeholder="Search Results"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
              onKeyDown={handleEnterKeyDown}
            />
            <span className="o-88 absolute center-v right-1 pe-none">
              <FontAwesomeIcon
                icon={["fas", "search"]}
                size="2x"
                className="fontawesome-icon dark-icon-color text-gray-50"
              />
            </span>
          </FormGroup>
        </Form>
      </div>
    </>
  )

  if ((loading) || userLoading) {
    return (
      <Container fluid>
        {heading}
        <Row>
          <Col>
            <div className='pane pane-table'>
              <PlaceHolder />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  if (user === null) {
    return (
      <Container fluid>
        {heading}
        <Row>
          <Col>
            <div className='pane pane-table'>
              <UncontrolledAlert color="danger">
                <h4>Invalid User</h4>
              </UncontrolledAlert>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }

  if (error || userError) {
    return (
      <Container fluid>
        {heading}
        <Row>
          <Col>
            <div className='pane pane-table'>
              <p>{error?.message}</p>
              <p>{userError?.message}</p>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  if (!loading && !!data  && data.org?.__typename === 'Client'  && data.org.documents && data.org.report) {
    const plan = find(data.org.plans, ["id", planId])
    const associationList = (document:FileFullDetailsFragment | ReportDocumentDetailsFragment) => {
      let managerAssociations:string[] = []
      let productAssociations:string[] = []
      let vehicleAssociations:string[] = []
      let clientAssociations:string[] = []
      let planAssociations:string[] = []
      let glidePathAssociations:string[] = []
      if("managers" in document)
        managerAssociations = compact(document.managers?.map((m) => m?.name)) || []
      if("products" in document)
        productAssociations = compact(document.products?.map((m) => m?.product?.name)) || []
      if("vehicles" in document)
        vehicleAssociations = compact(document.vehicles?.map((m) => m?.vehicle?.name)) || []
      if("clients" in document)
        clientAssociations = compact(document.clients?.map((m) => m?.name)) || []
      if("plans" in document)
        planAssociations = compact(document.plans?.map((m) => m?.name)) ||[]
      if("glidePaths" in document)
        glidePathAssociations = compact(document.glidePaths?.map((m) => m?.name)) || []
      return [...clientAssociations, ...planAssociations, ...managerAssociations, ...productAssociations, ...vehicleAssociations, ...glidePathAssociations] as string[]
    }

    const convertReport = (report?: ReportDocumentDetailsFragment | null) => {
      if(!report) return null
      const isPublished = !!report.liveList && report?.active
      if(!(isPublished || (!!report?.draftList && auth.checkPermissions(["view:draft_reports"])))) return null
      return {
        ...report,
        associations: associationList(report),
        type: report.category,
        subType: report.subCategory,
        lastUpdated: report.lastPublished,
        // asOfDate: (report.dueDates?.find((dueDate:any) => dueDate.quarterDate === appDate.format(DATE_API_FORMAT)) || {quarterDate: appDate.format(DATE_API_FORMAT), dueDate: null}).dueDate,
        clientId: clientId,
        planId: planId,
        versionId: "",
        mimeType: "",
        description: report.name,
        access: {
          value: isPublished ? "Callan, Clients" : "Callan Only"
        }
      }
    }
    let tableData: documentTableData[]
    if(plan){
      let documentData = uniqBy(compact(plan?.documents?.map((document) => {return {...document, clientId, planId, associations: associationList(document as FileFullDetailsFragment) }})), "id")
      let reportsData = uniqBy(compact(plan?.report?.map(convertReport)), 'id')
      tableData = [...documentData, ...reportsData] as documentTableData[]
    } else {
      let documentData = uniqBy(compact(get(data, "org.documents", [])?.map((document:FileFullDetailsFragment) => {return {...document, clientId, associations: associationList(document) }}) as documentTableData[]), 'id')
      let reportsData = uniqBy(compact(data?.org.report?.map(convertReport)), 'id')
      tableData = [...documentData, ...reportsData]  as documentTableData[]
    }

    let filteredDocuments = tableData?.filter((document: documentTableData) => {
      const permissions = getDocumentPermission({document: document as Document, page: "Client", auth})
      return permissions.view
    })
    let colDef = clientDocumentListColumnDef(auth.checkPermissions(["view:all_documents"]))
    const onReady = (api:any) => {
    }

    const { documentType, reportId } = match.params as { documentType: string, reportId: string }
    const urlWithoutReportId = reportId
      ? match.url.slice(0, -reportId.length - 1)
      : match.url
    const urlWithoutType = documentType
      ? urlWithoutReportId.slice(0, -documentType.length - 1)
      : urlWithoutReportId

    return (
      <Container fluid className="d-flex flex-grow-1 flex-direction-column">
        <AddFileModal
          inputRef={addFileInputRef}
          associationType={!!plan ? "Plan" : "Client"}
          associationId={!!plan ? planId : data.org.id}
          user={user}
          modalOpen={addFileModalOpen}
          setModalOpen={setAddFileModalOpen}
          setSelectedDocument={selectDocument}
          forceValues={{plan_ids: compact([planId]), client_ids: [data.org.id]}}
          defaultValues={{access: DocumentAccess._1}}
          auth={auth}
        />
        <Switch>
          <Route
            exact
            path={`${urlWithoutType}/documents/:documentId`}
            render={(params) =>
              <ClientDocumentDetail
                allDocuments={filteredDocuments || []}
                filteredDocuments={filteredDocuments || []}
                user={user}
                associationType={!!plan ? "Plan" : "Client"}
                auth={auth}
                clientId={clientId}
                planId={planId}
                history={history}
                location={location}
                match={params.match}
                typeMapping={data.fileTypeMap as FileTypeSubTypeMap[]}
                refetchQuery={refetch}
              />}
            >
          </Route>
          <Route
            path={`${urlWithoutType}/reports/:reportId`}
            render={(params) =>
              <ClientReportAsDocument
                filteredDocuments={filteredDocuments || []}
                auth={auth}
                clientId={clientId}
                planId={planId}
                history={history}
                match={params.match}
              />}
            >
          </Route>
          <Route path={`*`}>
            {heading}
              <SortableTable
                loading={loading}
                filterText={search}
                columnDefs={colDef}
                tableData={tableData}
                rowId={"id"}
                onReady={onReady}
              />
          </Route>
        </Switch>

      </Container>
    )
  }
  return <div>data doesn't exist.</div>
}



export default ClientDocumentsList
