import { disableFragmentWarnings, gql } from "@apollo/client"
import {
  PRODUCT_EMPLOYEE_BREAKDOWN_DETAIL_FRAGMENT,
  PRODUCT_EMPLOYEE_BREAKDOWN_OTHER_MINORITY_FRAGMENT,
} from "./ProductFragments"

disableFragmentWarnings()

export const MANAGER_ASSOCIATION_FRAGMENT = gql`
  fragment ManagerAssociationFragment on Manager {
    id
    name
  }
`

export const CLIENT_ASSOCIATION_FRAGMENT = gql`
  fragment ClientAssociationFragment on Client {
    id
    name
    shortName
  }
`

export const PRODUCT_ASSOCIATION_FRAGMENT = gql`
  fragment ProductAssociationFragment on Product {
    product {
      id
      name
      manager {
        id
        name
      }
    }
  }
`

export const VEHICLE_ASSOCIATION_FRAGMENT = gql`
  fragment VehicleAssociationFragment on Vehicle {
    vehicle {
      id: fundid
      name
      shortName: name
      product {
        product {
          id
          name
          manager {
            id
            name
          }
        }
      }
    }
  }
`

export const GLIDE_PATH_ASSOCIATION_FRAGMENT = gql`
  fragment GlidePathAssociationFragment on GlidePath {
    id
    name
    manager {
      id
      name
    }
  }
`

export const PLAN_ASSOCIATION_FRAGMENT = gql`
  fragment PlanAssociationFragment on Plan {
    id
    name
    client {
      id
      name
    }
    report(filters: {limit: 1000}) {
      id
      name
    }
  }
`

export const INTERACTION_SIDEBAR_FRAGMENT = gql`
  fragment InteractionSidebarFragment on Interaction {
    id
    date
    type
    subject
    importance
    assetClass
    callanPeople {
      id
    }
    notes {
      hasNotes
    }
    primaryManagerAssociation {
      id
      name
    }
    productAssociations {
      product {
        id
        name
      }
    }
  }
`

export const INTERACTION_NOTE_DETAIL_FRAGMENT = gql`
  fragment InteractionNoteDetailFragment on InteractionNote {
    id
    body
    author {
      id
      firstName
      lastName
    }
    date
  }
`

export const FILE_VERSIONS_FRAGMENT = gql`
  fragment FileVersionsFragment on File {
    versions {
      id
      person {
        id
        firstName
        lastName
      }
      updated
      reason
    }
  }
`

export const FILES_ZIP_FRAGMENT = gql`
  fragment FilesZipFragment on File {
    id
    versionId
    type {
      code
    }
    subType {
      code
    }
  }
`

export const FILE_BASIC_INFO_FRAGMENT = gql`
  fragment FileBasicInfoFragment on File {
    id
    versionId
    name
    description
    mimeType
    lastUpdated
    url
    type {
      code
      value
    }
    subType {
      code
      value
    }
    # CAL-2043
    asOfDate
    access {
      code
      value
    }
    clients {
      id
      name
    }
    ...FileVersionsFragment
  }
  ${FILE_VERSIONS_FRAGMENT}
`

export const FILE_ASSOCIATIONS_FRAGMENT = gql`
  fragment FileAssociationsFragment on File {
    managers {
      ...ManagerAssociationFragment
    }
    clients {
      ...ClientAssociationFragment
    }
    products {
      ...ProductAssociationFragment
    }
    vehicles {
      ...VehicleAssociationFragment
    }
    glidePaths {
      ...GlidePathAssociationFragment
    }
    plans {
      ...PlanAssociationFragment
    }
  }
  ${MANAGER_ASSOCIATION_FRAGMENT}
  ${PRODUCT_ASSOCIATION_FRAGMENT}
  ${VEHICLE_ASSOCIATION_FRAGMENT}
  ${GLIDE_PATH_ASSOCIATION_FRAGMENT}
  ${PLAN_ASSOCIATION_FRAGMENT}
  ${CLIENT_ASSOCIATION_FRAGMENT}
`

export const REPORT_DOCUMENT_DETAILS_FRAGMENT = gql`
  fragment ReportDocumentDetailsFragment on Report {
    id
    name
    active
    category {
      code
      value
    }
    plans {
      id
      name
    }
    subCategory {
      code
      value
    }
    lastPublished
    dueDates {
      dueDate
      quarterDate
    }
    liveList {
      id
    }
    draftList {
      id
    }
  }
`

export const FILE_FULL_DETAILS_FRAGMENT = gql`
  fragment FileFullDetailsFragment on File {
    ...FileBasicInfoFragment
    ...FileAssociationsFragment
  }
  ${FILE_BASIC_INFO_FRAGMENT}
  ${FILE_ASSOCIATIONS_FRAGMENT}
`

export const ERROR_DETAIL_FRAGMENT = gql`
  fragment ErrorDetailFragment on Error {
    name
    message
    stack
  }
`

export const FILE_PAYLOAD_FRAGMENT = gql`
  fragment FilePayloadFragment on FilePayload {
    file {
      ...FileFullDetailsFragment
    }
    error {
      ...ErrorDetailFragment
    }
  }
  ${FILE_FULL_DETAILS_FRAGMENT}
  ${ERROR_DETAIL_FRAGMENT}
`

export const PRODUCT_INTERACTION_NOTES_FRAGMENT = gql`
  fragment ProductInteractionNotesFragment on ProductInteractionNotes {
    product {
      product {
        id
        name
      }
    }
    summary {
      ...InteractionNoteDetailFragment
    }
    observation {
      ...InteractionNoteDetailFragment
    }
    internalOpinion @include(if: $showInternal) {
      ...InteractionNoteDetailFragment
    }
  }
  ${INTERACTION_NOTE_DETAIL_FRAGMENT}
`

export const ORGANIZATION_INTERACTION_NOTES_FRAGMENT = gql`
  fragment OrganizationInteractionNotesFragment on OrganizationInteractionNotes {
    summary {
      ...InteractionNoteDetailFragment
    }
    observation {
      ...InteractionNoteDetailFragment
    }
    internalOpinion @include(if: $showInternal) {
      ...InteractionNoteDetailFragment
    }
    # raw {
    #   ...InteractionNoteDetailFragment
    # }
  }
  ${INTERACTION_NOTE_DETAIL_FRAGMENT}
`

export const INTERACTION_FRAGMENT = gql`
  fragment InteractionFragment on Interaction {
    id
    date
    type
    contacts {
      id
      firstName
      lastName
    }
    callanPeople {
      id
      firstName
      lastName
    }
    subject
    primaryManagerAssociation {
      id
      name
      shortName
    }
    otherManagerAssociations {
      ...ManagerAssociationFragment
    }
    productAssociations {
      ...ProductAssociationFragment
    }
    glidePathAssociations {
      ...GlidePathAssociationFragment
    }
    documentAssociations {
      ...FileFullDetailsFragment
    }
    importance
    notes {
      hasNotes
      organizationNotes {
        ...OrganizationInteractionNotesFragment
      }
      allProductNotes {
        ...OrganizationInteractionNotesFragment
      }
      specificProductNotes {
        ...ProductInteractionNotesFragment
      }
    }
  }
  ${ORGANIZATION_INTERACTION_NOTES_FRAGMENT}
  ${PRODUCT_INTERACTION_NOTES_FRAGMENT}
  ${FILE_BASIC_INFO_FRAGMENT}
  ${MANAGER_ASSOCIATION_FRAGMENT}
  ${PRODUCT_ASSOCIATION_FRAGMENT}
  ${GLIDE_PATH_ASSOCIATION_FRAGMENT}
  ${FILE_FULL_DETAILS_FRAGMENT}
`

export const MANAGER_COMPLIANCE_FRAGMENT = gql`
  fragment ManagerComplianceFragment on Manager {
    compliance {
      regulatoryBody {
        name
        registrationNumber
        crdNumber
        secNumber
      }
      interestedInSecuritiesBrokerage
      interestedInRealEstateBrokerage
      registeredInvestmentAdvisor
      secAudit {
        lastAuditDate
        scopeOfAudit
      }
      gipsCompliance {
        isGipsCompliant
        complianceDate
        explanation
        accountingFirmName
      }
      registeredCFA
      litigation {
        litigation
        explanation
      }
      omissionsInsurance {
        covered
        amount
      }
      liabilityInsurance {
        covered
        amount
      }
      bonded {
        covered
        amount
      }
      disasterRecovery {
        plan
        date
        summary
      }
      regulatoryEnforcement {
        elevatedToEnforcement
        latestEnforcementDate
      }
    }
  }
`

export const MANAGER_OWNERSHIP_FRAGMENT = gql`
  fragment ManagerOwnershipFragment on OrgOwnershipPayload {
    ownership {
      id
      name
      title
      yearAdmittedAsOwner
      investmentTeamMember
      type {
        code
        value
      }
      ownershipAmount {
        year
        economicOwnershipPercent
        controllingOwnershipPercent
        updateDate
      }
    }
  }
`

export const MANAGER_OWNERSHIP_DIVERSE_TABLES_FRAGMENT = gql`
  fragment ManagerOwnershipDiversifyFragment on Manager {
    id
    gender: employeeOwnershipGender(filters: $filters) {
      year
      type: ownerType {
        code
        value
      }
      percent
    }
    ethnicity: employeeOwnershipEthnicity(filters: $filters) {
      year
      type: ownerType {
        code
        value
      }
      percent
    }
    other: employeeOwnershipOtherMinority(filters: $filters) {
      year
      type: ownerType {
        code
        value
      }
      percent
    }
  }
`

export const MANAGER_EMPLOYEE_BREAKDOWN_DETAIL_FRAGMENT = gql`
  fragment ManagerEmployeeBreakdownDetailFragment on Manager {
    employeeBreakdownDetail {
      canProvideDiversityGender
      reason {
        code
        value
      }
      explanation
    }
  }
`

export const MANAGER_EMPLOYEES_BREAKDOWN_OTHER_MINORITY_FRAGMENT = gql`
  fragment ManagerEmployeeBreakdownByOtherMinorityFragment on Manager {
    employeeBreakdownByOtherMinority(startDate: $startDate, endDate: $endDate) {
      date
      type {
        code
        value
      }
      count
    }
  }
`

export const MANAGER_EMPLOYEES_FRAGMENT = gql`
  fragment ManagerEmployeesFragment on Manager {
    id
    employeeTurnover(startDate: $startDate, endDate: $endDate) {
      date
      assetClass {
        id
        fullName
        shortName
      }
      type {
        code
        value
      }
      count
      gained
      lost
    }
    employeeBreakdownByGender(startDate: $startDate, endDate: $endDate) {
      date
      type {
        code
        value
      }
      count
    }
    employeeBreakdownByJobCategory(startDate: $startDate, endDate: $endDate) {
      date
      type {
        code
        value
      }
      count
    }
    employeeBreakdownByDiversityProfile(
      startDate: $startDate
      endDate: $endDate
    ) {
      date
      type {
        code
        value
      }
      count
    }
    ...ManagerEmployeeBreakdownDetailFragment
    ...ManagerEmployeeBreakdownByOtherMinorityFragment
  }
  ${MANAGER_EMPLOYEE_BREAKDOWN_DETAIL_FRAGMENT}
  ${MANAGER_EMPLOYEES_BREAKDOWN_OTHER_MINORITY_FRAGMENT}
`

export const MANAGER_ESG_SCORE_FRAGMENT = gql`
  fragment ManagerEsgScoreFragment on Manager {
    callanEsgScores {
      weightedScore
      numberAnswered
      completeAnswer
    }
  }
`

export const MANAGER_ESG_FRAGMENT = gql`
  fragment ManagerEsgFragment on Manager {
    managerEsgPolicy {
      managerEsgPolicyDescription {
        cfaDisclosure
        numberVendors {
          code
          value
        }
        netZeroSignatory
        netZeroTargets
        firmWideEsgPolicy
        policyDescription
        yearPolicyInPlace
        publishReport
        hasDedicatedESGProfessionals
        dedicatedEsgOversight
        numberOfDedicatedProfessionals
        accessibleCentralizedRepository
        outsideVendorResearch
        esgIntegratedIntoResearch
        unitedNationsPrinciplesSignatory
        signatoryToESGBodies
        signatoryBodyList {
          code: signatoryType
          value: signatoryName
        }
        reduceCarbonFootprint
        measureCarbonFootprint
        carbonFootprintAssetClass {
          code
          value
        }
        carbonFootprintScope {
          code
          value
        }
        carbonEmissionMeasurementSource {
          code
          value
        }
        belongsToAdvocacyGroups
      }
      managerIssuerEsgEngagementPolicy {
        issuerEngagementInPastYear
        issuerEngagementIncorporatedInEsgProcess
        # numberOfTopTenIssuerEngagementOverPastYear
        numberOfIssuerEngagementOverPastYear
      }
      managerProxyVotingEsgGuideline {
        sameProxyVotingGuidelineAcrossAllStrategiesAtTheFirm
        voteOwnProxies
        # proxyVotingPolicyAddressesEnvironmentalIssues
        # proxyVotingPolicyAddressesSocialIssues
        # proxyVotingPolicyAddressesGovernanceIssues
        proxyVotingPolicyAddressesESG
      }
    }
  }
`
export const CALLAN_DEI_SCORES = gql`
  fragment CallanDeiScores on Manager {
    callanDeiScores {
      weightedScore
      numberAnswered
      completeAnswer
    }
  }
`

export const MANAGER_DIVERSITY_FRAGMENT = gql`
  fragment ManagerDiversityFragment on Manager {
    ...CallanDeiScores
    managerEsgDiversityInclusionPolicy {
      policiesToIncDiversity
      diversityOversightTypes {
        oversightType {
          code
          value
        }
        oversightText
      }
      trackKpisOfPolicies
      kpisAvailableExternal
      diversityInclusionPolicy
      recruitmentInitiatives
      recruitmentInitiativesDesc
      offersDandiTraining
      frequencyOfTrainingOffered {
        code
        value
      }
      suppliersMwdo
      mentorshipProgram
      undertakenPayGapStudy
      policyForDeficiencies
      cfaSignatory
      payParityPolicy
      ethicsCodeOfConduct
      freqUpdatesToCode {
        code
        value
      }
      sexualHarassPolicy
      freqEmpSignOrReview {
        code
        value
      }
    }
  }
  ${CALLAN_DEI_SCORES}
`

export const MANAGER_ASSETS_CLOSED_END = gql`
  fragment ManagerAssetsClosedEndFragment on Manager {
    closedEndedFundraising {
      year
      raisedCapital
      contributedCapital
      uncalledCapital
      netAssetValue
    }
  }
`

export const MANAGER_ASSETS_CLIENT = gql`
  fragment ManagerAssetsClientFragment on Manager {
    assetsByClient(
      startDate: $startDate
      endDate: $endDate
      clientType: $clientType
      categoryType: $categoryType
    ) {
      quarterEndDate
      updateDate
      assetsUnderManagement
      numberOfAccounts
      clientType {
        code
        value
      }
      categoryType {
        code
        value
      }
      origin {
        code
        value
      }
    }
    otherAssetExplanation(startDate: $startDate, endDate: $endDate) {
      quarterEndDate
      client
    }
    managerTypes {
      code
    }
  }
`

export const MANAGER_ASSETS_VEHICLES = gql`
  fragment ManagerAssetsVehiclesFragment on Manager {
    assetsByVehicle(startDate: $startDate, endDate: $endDate) {
      quarterEndDate
      vehicleType {
        code
        value
      }
      assetClass {
        code
        value
      }
      assetsUnderManagement
      numberOfAccounts
      origin {
        code
      }
    }
    privateEquityAssetsByCapitalType(startDate: $startDate, endDate: $endDate) {
      quarterEndDate
      strategy {
        code
        value
      }
      capitalType {
        code
        value
      }
      assetsUnderManagement
      numberOfAccounts
    }
    privateEquityAssetsByStrategyType(
      startDate: $startDate
      endDate: $endDate
    ) {
      quarterEndDate
      strategy {
        code
        value
      }
      assetsUnderManagement
      numberOfAccounts
    }
    realAssetsAssetsByStrategyType(startDate: $startDate, endDate: $endDate) {
      quarterEndDate
      strategy {
        code
        value
      }
      vehicleType {
        code
        value
      }
      assetsUnderManagement {
        gross
        net
      }
      numberOfAccounts
    }
    realAssetsAssetsByCapitalType(startDate: $startDate, endDate: $endDate) {
      quarterEndDate
      strategy {
        code
        value
      }
      capitalType {
        code
        value
      }
      assetsUnderManagement
      numberOfAccounts
    }
    otherAssetExplanation(startDate: $startDate, endDate: $endDate) {
      quarterEndDate
      vehicle
    }
  }
`

export const PRODUCT_ESG_FRAGMENT = gql`
  fragment ProductESGFragment on Product {
    product {
      id
      assetClass {
        id
        parent {
          id
        }
      }
      esgPolicy {
        productEsgProcess {
          strategyIncludesExclusionaryScreening
          managedWithEsgConsiderations
          managedWithEsgConsiderationsPrimaryObjective
          useScreens
          optionExclusionList {
            code: exclusionType
            value: exclusionDescription
          }
          securityLevelEsgRiskAndOpportunityProprietaryMonitoring
          portfolioLevelEsgRiskAndOpportunityProprietaryMonitoring
          issuerLevelEsgSubjectiveDecisionMaking
          issuerLevelEsgObjectiveDecisionMaking
          useProprietaryScoringForEsgResearch
          monitorsReportsOnGoals
          inputAndMethodologyDescription
          directAndMeasurableImpactObjectiveBasedOnAnyOfEsgPillars
          directAndMeasurableImpactObjectiveBasedOnAnyOfEsgPillarsDescription
          strategyDescribedAsThematicForAnyEsgComponent
          strategyDescribedAsThematicForAnyEsgComponentList {
            code: componentType
            value: componentDescription
          }
          includeForwardLookingEsgMetrics
          regularlyMateriallyAssessedProcessAcrossSectorAndIndustry
          fixedIncomeConsiderationOfProceeds
          clientReportProductionHighlightingEsgMetricsOfHoldings
          investmentTeamInvolvedProxyVoting
          cfaDisclosure
          sfdr8
          sfdr9
        }
        productEsgTheme {
          alignedWithUNSustainableDevelopmentGoals
          engagedWithIssuerAboutEnvironmentalMatter
          engagedWithIssuerAboutSocialMatter
          engagedWithIssuerAboutGovernanceMatter
        }
      }
    }
  }
`

const __PORTFOLIO_CONSTRUCTION_METHOD_FRAGMENT = gql`
  fragment __PortfolioConstructionMethod on Product {
    ... on OpenEnded {
      ... on Equity {
        equity {
          portfolioConstructionMethod {
            code
            value
          }
        }
      }
      ... on FixedIncome {
        fixedIncome {
          portfolioConstructionMethod {
            code
            value
          }
        }
      }
      ... on RealAssets {
        realAssets {
          portfolioConstructionMethod {
            code
            value
          }
        }
      }
    }
  }
`

export const PRODUCT_MANAGEMENT_PASSIVE_FRAGMENT = gql`
  fragment ProductManagementPassiveFragment on Product {
    ... on PassiveProduct {
      passiveProduct {
        passivePortfolioConstruction {
          code
          value
        }
        attributedTrackingError {
          securitiesLending
          constructionMethodology
          other
        }
      }
    }
  }
`

export const PRODUCT_MANAGEMENT_FRAGMENT = gql`
  fragment ProductManagementFragment on Product {
    ...__PortfolioConstructionMethod
    product {
      id
      assetClass {
        id
        parent {
          id
        }
      }
      compliance {
        currentTeamStartDate
        trackRecordComments
        previousFirmTrackRecord
        GIPSMethodology
        GIPSAuditor
        Auditor
        LastAuditDate
        verifiedPerformanceBegan
        performanceExamConcluded
        administrator
        custodian
      }
      # //Management
      managementType {
        code
        value
      }
      investmentManagementStrategy {
        code
        value
      }
      currencyHedging {
        code
        value
      }

      valueAdded {
        type {
          value
          code
        }
        weight
      }
      performanceCompositeDispersion {
        quarterEndDate
        compositeAccounts
        accountsExplanation
        minReturn
        maxReturn
        weightedStandardDeviation
      }
    }
  }
  ${__PORTFOLIO_CONSTRUCTION_METHOD_FRAGMENT}
`

const PRODUCT_GPCOMMITMENTS_SHARED_CLOSED_PRIVATE_FRAGMENT = gql`
  fragment ProductGpCommitmentsSharedClosedPrivateFragment on ClosedEnded {
    closedEnded {
      generalPartnersCommitments {
        entityId
        participantType {
          code
        }
        legalName
        currentTitle
        investmentTeamMember
        carriedInterest
        sponsorCommitType {
          code
        }
        percentOfLimitedPartnersCommitment
        percentOfSponsorCommitment
      }
      investorRosters {
        investorId
        investor
        capitalCommitment
        investorType {
          code
        }
        investorDomicile {
          code
          value
        }
        advisorBoardSeat
      }
    }
  }
`

export const PRODUCT_GP_COMMITMENTS_FRAGMENT = gql`
  fragment ProductGPCommitmentsFragment on Product {
    product {
      id
    }
    ... on PrivateEquity {
      ... on ClosedEnded {
        ...ProductGpCommitmentsSharedClosedPrivateFragment
      }
    }
    ... on RealAssets {
      ... on ClosedEnded {
        ...ProductGpCommitmentsSharedClosedPrivateFragment
      }
    }
    ... on PrivateCredit {
      ... on ClosedEnded {
        ...ProductGpCommitmentsSharedClosedPrivateFragment
      }
    }
  }
  ${PRODUCT_GPCOMMITMENTS_SHARED_CLOSED_PRIVATE_FRAGMENT}
`

const RISK_POLICY_RANGE_FRAGMENT = gql`
  fragment RiskPolicyRangeFragment on RiskPolicyRange {
    low
    high
    target
    explanation
  }
`

const RISK_TARGET_FRAGMENT = gql`
  fragment RiskTargetFragment on RiskTarget {
    low
    high
  }
`

const RISK_STATISTIC_TARGET_FRAGMENT = gql`
  fragment RiskStatisticTargetFragment on RiskStatisticsTargets {
    excessReturn {
      ...RiskTargetFragment
    }
    relativeReturn {
      ...RiskTargetFragment
    }
    trackingError {
      ...RiskTargetFragment
    }
    sharpeRatio {
      ...RiskTargetFragment
    }
    informationRatio {
      ...RiskTargetFragment
    }
    equityBeta {
      ...RiskTargetFragment
    }
    valueAtRisk {
      ...RiskTargetFragment
    }
  }
  ${RISK_TARGET_FRAGMENT}
`

const FINANCING_TYPE_DETAILS_FRAGMENT = gql`
  fragment FinancingTypeDetailsFragment on FinancingTypeDetails {
    used
    limit
    basis {
      code
    }
  }
`

const DEAL_POLICY_FRAGMENT = gql`
  fragment DealPolicyFragment on DealPolicy {
    lower
    higher
    average
    median
    explanation
  }
`

const REAL_ASSETS_DEAL_POLICY_FRAGMENT = gql`
  fragment RealAssetsDealPolicyFragment on DealPolicy {
    lower
    higher
    explanation
  }

`

const PRODUCT_GUIDELINE_MANAGEMENT_POLICY_FRAGMENT = gql`
  fragment ManagementPolicyFragment on Product {
    product {
      riskManagementPolicy {
        portfolioLeverage {
          ...RiskPolicyRangeFragment
        }
        hedging {
          ...RiskPolicyRangeFragment
        }
      }
    }
  }
`

const PRODUCT_GUIDELINE_SHARED_CLOSED_PRIVATE_FRAGMENT = gql`
  fragment ProductGuidelineSharedClosedPrivateFragment on ClosedEnded {
    closedEnded {
      financingType {
        shortTerm {
          ...FinancingTypeDetailsFragment
        }
        longTerm {
          ...FinancingTypeDetailsFragment
        }
      }
      dealPolicies {
        enterpriseValue {
          ...DealPolicyFragment
        }
        trailing12mEBITDA {
          ...DealPolicyFragment
        }
        dealSize {
          ...DealPolicyFragment
        }
        revenue {
          ...DealPolicyFragment
        }
        numberOfInvestments {
          ...DealPolicyFragment
        }
      }
    }
  }
`

export const PRODUCT_GUIDELINES_FRAGMENT = gql`
  fragment ProductGuidelineFragment on Product {
    product {
      id
    }
    ... on OpenEnded {
      openEnded {
        riskStatisticsTargets {
          # on OpenEnded
          ...RiskStatisticTargetFragment
        }
        longShortPolicy {
          # on OpenEnded
          totalGrossExposure
          grossShortExposure
          grossLongExposure
        }
      }
      ... on PrivateEquity {
        ... on Product {
          ...ManagementPolicyFragment
        }
      }
      ... on PrivateCredit {
        ... on Product {
          ...ManagementPolicyFragment
        }
      }
      ... on Equity {
        ... on Product {
          ...ManagementPolicyFragment
        }
      }
      ... on TargetDate {
        ... on Product {
          ...ManagementPolicyFragment
        }
      }
      ... on FixedIncome {
        ... on Product {
          ...ManagementPolicyFragment
        }
      }
      ... on MultiAssetClass {
        ... on Product {
          ...ManagementPolicyFragment
        }
      }
    }
    ... on HedgeFund {
      ... on Product {
        product {
          volatility {
            annualized # for those without benchmark
            benchmarkSpread {
              benchmark
              spread
            }
          }
        }
      }
    }
    ... on RealAssets {
      realAssets {
        limitations {
          # on RealAssets
          cash
          geography
          jointVenture
          nonCoreAllocation
          other
          propertyType
        }
        propertyLeverage {
          ...RiskPolicyRangeFragment
        }
      }
      ... on Product {
        ...ManagementPolicyFragment
      }
      ... on ClosedEnded {
        closedEnded {
          dealPolicies {
            dealSize {
              ...RealAssetsDealPolicyFragment
            }
            numberOfInvestments {
              ...RealAssetsDealPolicyFragment
            }
            developmentLimits {
              ...RealAssetsDealPolicyFragment
            }
            singleAssetLimits {
              ...RealAssetsDealPolicyFragment
            }
          }
        }
      }
    }
    ... on PrivateEquity {
      ... on ClosedEnded {
        ...ProductGuidelineSharedClosedPrivateFragment
      }
    }
    ... on PrivateCredit {
      ... on ClosedEnded {
        ...ProductGuidelineSharedClosedPrivateFragment
      }
    }
  }
  ${RISK_POLICY_RANGE_FRAGMENT}
  ${RISK_STATISTIC_TARGET_FRAGMENT}
  ${FINANCING_TYPE_DETAILS_FRAGMENT}
  ${DEAL_POLICY_FRAGMENT}
  ${PRODUCT_GUIDELINE_MANAGEMENT_POLICY_FRAGMENT}
  ${REAL_ASSETS_DEAL_POLICY_FRAGMENT}
  ${PRODUCT_GUIDELINE_SHARED_CLOSED_PRIVATE_FRAGMENT}
`

export const PRODUCT_PROCESS_FRAGMENT = gql`
  fragment ProductProcessFragment on Product {
    __typename
    product {
      id
      assetClass {
        id # for querying possible options/tabs
      }
      process {
        type {
          code
          description
          question
        }
        body
        lastUpdated
        history {
          body
          lastUpdated
        }
      }
    }
  }
`

// TODO: how to use ctd in an elegant way.
export const MISC_PORTFOLIO_INFO_FRAGMENT = gql`
  fragment MiscPortfolioInfoFragment on Characteristics {
    date
    active
    americanDepositoryReceipt
    annualPercentTurnover
    bonds
    cash
    commodities
    commonStock
    convertible
    convertibles
    convertibleCtd # ctd
    durationAroundIndexLowerBound
    durationAroundIndexUpperBound
    durationVariationVsBenchPct
    grossLongExposure
    grossShortExposure
    inflationLinkedSecurities
    inflationLinkedSecuritiesCtd # ctd
    netDevMktCurrencyExposure
    netEmgMktCurrencyExposure
    nonConvertPrefd
    nonInvestmentGradeDebt
    nonInvestmentGradeDebtCtd # ctd
    otherMisc
    pooledVehicle
    privatePlacements144a
    privatePlacements144aCtd # ctd
    realEstateInvestmentTrust
    realEstateOperatingCo
    totalEmergMktExposure
    totalEmergMktExposureCtd # ctd
    totalGrossShortExposure
    totalNonUsDeveloped
    totalNonUsDevelopedCtd # ctd
  }
`

export const PORTFOLIO_CHARACTERISTICS_FRAGMENT = gql`
  fragment PortfolioCharacteristicsFragment on Characteristics {
    date
    annualPercentTurnover
    avgAgeOfAssets
    avgDividendYield
    avgInvestmentSize
    avgLeverage
    avgLiquidityMonths
    avgMarketCap
    avgMgrLeverage
    avgPriceBookValue
    avgPriceForecastedEarnings
    betaVsBarclaysGlobalAgg
    betaVsBarclaysUsAgg
    betaVsMsciAcwi
    betaVsRussell3000
    couponRate
    currentExAnteRisk
    currentLeverage
    currentValueAtRisk
    currentYield
    derivativesPctOfGrossNotionalExp
    directlyOperatedPct
    discountRate
    dts20Ratio
    dtsRatio
    effectiveConvexity
    effectiveDurationYrs
    effectiveMaturity
    effectiveRealDurationYrs
    effectiveRealYield
    effectiveYieldYtw
    forecastedEarningsGrowth5yr
    forecastGrowthFfo
    grossAnnualCreditingRate
    historicalEarningsGrowth5yr
    impliedCapRate
    incomeReturnTarget
    investmentsMajorityOwned
    investmentsMinorityOwned
    investmentsWhollyOwned
    jointVentures
    largeCapPct
    largestCorpCreditIssuerPct
    largestPosition
    leasedToOperatingPartnerPct
    liquidityLvl1AssetsPct
    liquidityLvl2AssetsPct
    liquidityLvl3AssetsPct
    longShortGrossMktExposure
    longShortNetMktExposure
    medianMarketCap
    microCapPct
    midCapPct
    mktToBookValRatio
    nonDirectPropertyInvestments
    numDistinctIssuers
    numOfHoldings
    numOfParticipants
    numOfRealizations
    numOfWrapProviders
    occupancy
    physicalAssetsPct
    portfolioDebt
    portfolioDebtInterestRate
    portfolioLeverage
    priceFfo
    priceNav
    projectedEquityMultiple
    projectedInternalRateOfReturn
    propertyDebt
    qualityRating
    realReturnTarget
    returnTarget
    smallCapWgt
    squareFeetInPortfolio
    subadvisedAssetsOfGrossExposures
    top5ParticipantsOwnedPct
    totalGrossExposure
    totalGrossLongExposure
    totalGrossShortExposure
    totalManagedInternally
    totalNetExposurePct
    yieldOfUnderlyingAssets
  }
`

export const CHARACTERISTICS_FRAGMENT = gql`
  fragment CharacteristicsFragment on WeightedExposures {
    bondSectorExposures(startDate: $startDate, endDate: $endDate) {
      date
      type {
        code
      }
      value {
        active
      }
      contributionToDuration
      contributionToDurationTimeSpread
      relativeContributionToDurationTimeSpread
    }
    corporateSubSectorIndustryExposures(
      startDate: $startDate
      endDate: $endDate
    ) {
      date
      type {
        code
      }
      value
      contributionToDuration
      contributionToDurationTimeSpread
      relativeContributionToDurationTimeSpread
    }
    countryExposures(startDate: $startDate, endDate: $endDate) {
      date
      type {
        code
      }
      value
      contributionToDuration
      relativeContributionToDurationTimeSpread
    }
    currencyExposures(startDate: $startDate, endDate: $endDate) {
      date
      type {
        code
      }
      value
    }
    directional(startDate: $startDate, endDate: $endDate) {
      date
      type {
        code
      }
      value
    }
    durationExposures(startDate: $startDate, endDate: $endDate) {
      date
      type {
        code
      }
      value {
        active
      }
      contributionToDuration
    }
    eventDriven(startDate: $startDate, endDate: $endDate) {
      date
      type {
        code
      }
      value
    }
    farmlandSectors(startDate: $startDate, endDate: $endDate) {
      date
      type {
        code
      }
      value
    }
    infrastructureSectors(startDate: $startDate, endDate: $endDate) {
      date
      type {
        code
      }
      value
    }
    investmentType(startDate: $startDate, endDate: $endDate) {
      date
      type {
        code
      }
      value
    }
    privateEquityGlobalGeographies(startDate: $startDate, endDate: $endDate) {
      date
      type {
        code
      }
      value
    }
    privateEquityIndustries(startDate: $startDate, endDate: $endDate) {
      date
      type {
        code
      }
      value
    }
    privateEquityUnitedStatesGeographies(
      startDate: $startDate
      endDate: $endDate
    ) {
      date
      type {
        code
      }
      value
    }
    propertySectors(startDate: $startDate, endDate: $endDate) {
      date
      type {
        code
      }
      value
      contributionToDuration
    }
    qualityExposures(startDate: $startDate, endDate: $endDate) {
      date
      type {
        code
      }
      value {
        active
      }
      contributionToDuration
    }
    realEstateInvestmentSizeDiversifications(
      startDate: $startDate
      endDate: $endDate
    ) {
      date
      type {
        code
      }
      value
    }
    realEstateInvestmentStageDiversifications(
      startDate: $startDate
      endDate: $endDate
    ) {
      date
      type {
        code
      }
      value
    }
    realEstateRegionalExposures(startDate: $startDate, endDate: $endDate) {
      date
      type {
        code
      }
      value
      contributionToDuration
    }
    realReturnExposures(startDate: $startDate, endDate: $endDate) {
      date
      type {
        code
      }
      value
    }
    regionalExposures(startDate: $startDate, endDate: $endDate) {
      date
      type {
        code
      }
      value
      contributionToDuration
    }
    relativeValues(startDate: $startDate, endDate: $endDate) {
      date
      type {
        code
      }
      value
    }
    sectors(startDate: $startDate, endDate: $endDate) {
      date
      type {
        code
      }
      value
    }
    stockSectors(startDate: $startDate, endDate: $endDate) {
      date
      type {
        code
      }
      value
    }
    strategies(startDate: $startDate, endDate: $endDate) {
      date
      type {
        code
      }
      value
    }
    timberlandRegions(startDate: $startDate, endDate: $endDate) {
      date
      type {
        code
      }
      value
    }
    timberlandWoodTypes(startDate: $startDate, endDate: $endDate) {
      date
      type {
        code
      }
      value
    }
    unitedStatesGeographicExposures(startDate: $startDate, endDate: $endDate) {
      date
      type {
        code
      }
      value
    }
    wrap(startDate: $startDate, endDate: $endDate) {
      date
      type {
        code
      }
      value
    }
  }
`

export const CHARACTERISTICS_DEFAULT_FRAGMENT = gql`
  fragment CharacteristicsDefaultFragment on Characteristics {
    active
    country {
      code
    }
    date
    region {
      code
    }
    sector {
      code
    }
    surveyed
    substitution {
      code
    }
  }
`

export const PRODUCT_CHARACTERISTICS_FRAGMENT = gql`
  fragment ProductCharacteristicsFragment on Product {
    product {
      id
      structure {
        code
      }
      assetClass {
        id
        shortName
        fullName
        parent {
          id
          shortName
          fullName
        }
      }
      vehicles {
        vehicle {
          id: fundid
          name
          status {
            code
            value
          }
          inceptionDate
          category {
            code
            value
          }
          identifiers {
            ticker
            cusip
          }
          isRepresentative
          relatedClientPortfolioManagerDataCollect {
            id
            name
            client {
              id
              shortName
              name
            }
            relatedVehicle {
              vehicle {
                id: fundid
                name
                category {
                  code
                  value
                }
                type {
                  code
                  value
                }
              }
            }
            clientSpecificDataCollectionFields {
              nameOverride
              questionnaireCharacteristicsRequired
              questionnaireHoldingsRequired
              questionnaireCashFlowRequired
              questionnaireSummaryCashFlowRequired
            }
          }
          vehicleDataCollection {
            characteristicsRequired
            holdingsRequired
          }
        }
        ... on OpenEndedMutualFund {
          openEndedVehicle {
            latestAssetsUnderManagement
          }
        }
        ... on OpenEndedExchangeTradedFund {
          openEndedVehicle {
            latestAssetsUnderManagement
          }
        }
        ... on OpenEndedPooledVehicle {
          openEndedVehicle {
            latestAssetsUnderManagement
          }
        }
        ... on OpenEndedVariableAnnuity {
          openEndedVehicle {
            latestAssetsUnderManagement
          }
        }
        ... on OpenEndedCollectiveInvestmentFundComposite {
          openEndedVehicle {
            latestAssetsUnderManagement
          }
        }
        ... on OpenEndedSeparateAccount {
          openEndedVehicle {
            latestAssetsUnderManagement
          }
        }
        ... on OpenEndedPooledVehiclePrivateEquity {
          openEndedVehicle {
            latestAssetsUnderManagement
          }
        }
        ... on OpenEndedPooledVehicleRealAssets {
          openEndedVehicle {
            latestAssetsUnderManagement
          }
        }
        ... on OpenEndedSeparateAccountRealAssets {
          openEndedVehicle {
            latestAssetsUnderManagement
          }
        }
        ... on OpenEndedPrivateNonRegisteredHedgeFund {
          openEndedVehicle {
            latestAssetsUnderManagement
          }
        }
        ... on OpenEndedCollectiveInvestmentFundStableValueComposite {
          openEndedVehicle {
            latestAssetsUnderManagement
          }
        }
        ... on OpenEndedCollectiveInvestmentFundStableValue {
          openEndedVehicle {
            latestAssetsUnderManagement
          }
        }
        ... on OpenEndedSeparateAccountStableValue {
          openEndedVehicle {
            latestAssetsUnderManagement
          }
        }
        ... on OpenEndedCollectiveInvestmentFund {
          openEndedVehicle {
            latestAssetsUnderManagement
          }
        }
        ... on OpenEndedPooledVehiclePrivateCredit {
          openEndedVehicle {
            latestAssetsUnderManagement
          }
        }
      }
    }
  }
`

export const ASSET_CLASS_FRAGMENT = gql`
  fragment AssetClassFragment on AssetClass {
    id
    code: id
    value: fullName
  }
`
export const CURRENT_ASSET_CLASS_FRAGMENT = gql`
  fragment CurrentAssetClassFragment on AssetClass {
    # code alias is consistent with AssetClassFragment used to get all possible options
    id
    code: id
    parent {
      id
      code: id
    }
  }
`

export const CURRENT_ASSET_CLASS_VALUE_FRAGMENT = gql`
  fragment CurrentAssetClassValueFragment on AssetClass {
    # code alias is consistent with AssetClassFragment, for readonly fields
    ...AssetClassFragment
    parent {
      ...AssetClassFragment
    }
  }
  ${ASSET_CLASS_FRAGMENT}
`

export const PRODUCT_SUMMARY_FRAGMENT = gql`
  fragment ProductSummaryFragment on Product {
    __typename
    product {
      name
      id
      assetClass {
        ...CurrentAssetClassFragment
      }
      primaryBenchmark {
        #HF & MAC
        code: id
      }
      targetBenchmarkAdd #add %
      activePassive
      inactive
      capitalStructure
      structure {
        code
        value
      }
      totalCapacity # Open End
      primaryGeographicRegion {
        code
        value
      }
      incomeReturnTarget {
        ...RiskPolicyRangeFragment
      }
      hardCap
      materialChanges {
        date
        description
      }
      documents {
        ...FileBasicInfoFragment
      }
    }
    ... on MultiAssetClass {
      ... on Product {
        product {
          blendedBenchmark {
            percent
            explanation
            benchmark {
              code
            }
          }
        }
      }
    }
    ... on OpenEnded {
      openEnded {
        associatedSymbols {
          ticker
          cusip
        }
      }
    }
    ... on ClosedEnded {
      closedEnded {
        vintageYearFirstCashflow
        vintageYearLegalInception
        irrTarget {
          net {
            ...RiskPolicyRangeFragment
          }
          gross {
            ...RiskPolicyRangeFragment
          }
        }

        targetRaise
        finalCommitSize
        minimumCommitment
        sponsorCommitment
        investmentPeriod
        term
        extensions {
          numberAllowed
          lengthAllowed
          numberTaken
        }
        # finalCommitSize  # not in interface
        fundRaising {
          date
          committedCapital
        }
        projectedCloseDates
        fundFocus {
          code
          value
        }
        controlRights {
          code
          value
        }
        targetTotalValuePaidIn {
          net {
            ...RiskPolicyRangeFragment
          }
          gross {
            ...RiskPolicyRangeFragment
          }
        }
      }
      ... on RealAssets {
        realAssets {
          debtMaturitySchedule {
            year
            property
            fund
          }
        }
      }
    }
    ... on RealAssets {
      realAssets {
        realReturnTarget {
          ...RiskPolicyRangeFragment
        }
        primaryRealAssetSectorsTarget {
          code
          value
        }
        primaryUSRegions {
          code
          value
        }
      }
    }
    ... on PrivateEquity {
      privateEquity {
        targetInvestmentStyle {
          code
          value
        }
        targetPrimaryGICSSectors {
          code
          value
        }
      }
    }
    ... on HedgeFund {
      hedgeFund {
        primaryHedgeFundAssetType {
          code
          value
        }
        primaryHedgeFundInvestmentStyle {
          code
          value
        }
        primaryHedgeFundSectorsTarget {
          code
          value
        }
        directionalBias {
          code
          value
        }
      }
    }
    ... on PrivateCredit {
      privateCredit {
        targetGrossYield
        targetOriginationFees
        targetInvestmentStyle {
          code
          value
        }
      }
    }
  }
  ${CURRENT_ASSET_CLASS_FRAGMENT}
  ${RISK_POLICY_RANGE_FRAGMENT}
  ${FILE_BASIC_INFO_FRAGMENT}
`

export const PRODUCT_EMPLOYEES_FRAGMENT = gql`
  fragment ProductEmployeesFragment on Product {
    __typename
    product {
      id
      name
      assetClass {
        ...CurrentAssetClassFragment
      }
      employeeBreakdownByDiversityProfile(
        startDate: $startDate
        endDate: $endDate
      ) {
        date
        type {
          code
          value
        }
        count
      }

      employeeBreakdownByGender(startDate: $startDate, endDate: $endDate) {
        date
        type {
          code
          value
        }
        count
      }

      employeeTurnover(startDate: $startDate, endDate: $endDate) {
        date
        type {
          code
          value
        }
        count
        gained
        lost
      }
      ...ProductEmployeeBreakdownDetailFragment
      ...ProductEmployeeBreakdownOtherMinorityFragment
    }
  }
  ${CURRENT_ASSET_CLASS_FRAGMENT}
  ${PRODUCT_EMPLOYEE_BREAKDOWN_DETAIL_FRAGMENT}
  ${PRODUCT_EMPLOYEE_BREAKDOWN_OTHER_MINORITY_FRAGMENT}
`

export const PRODUCT_ASSETS_CLIENT_TYPE_FRAGMENT = gql`
  fragment ProductAssetsByClientTypeFragment on Product {
    __typename
    product {
      id
      name
      assetsByClient(
        startDate: $startDate
        endDate: $endDate
        clientType: $clientType
        categoryType: $categoryType
      ) {
        quarterEndDate
        clientType {
          code
        }
        categoryType {
          code
        }
        assetsUnderManagement
        numberOfAccounts
        origin {
          code
        }
      }
      assetsByClientOtherExplanation(startDate: $startDate, endDate: $endDate) {
        date
        explanation
      }
    }
  }
`

export const PRODUCT_ASSETS_REGION_FRAGMENT = gql`
  fragment ProductAssetsByRegionFragment on Product {
    __typename
    product {
      id
      name
      assetsByRegion(
        startDate: $startDate
        endDate: $endDate
        region: $regionType
      ) {
        quarterEndDate
        region {
          code
          value
        }
        assetsUnderManagement
        numberOfAccounts
        origin {
          code
        }
        updateDate
      }
    }
  }
`

export const PRODUCT_ASSETS_VEHICLES_FRAGMENT = gql`
  fragment ProductAssetsByVehicleFragment on Product {
    product {
      id
      name
      assetsByVehicle(startDate: $startDate, endDate: $endDate) {
        quarterEndDate
        assetsUnderManagement
        vehicleType {
          code
          value
        }
        numberOfAccounts
        updateDate
      }
    }
  }
`

export const VEHICLE_PERFORMANCE_FRAGMENT = gql`
  fragment VehiclePerformanceFragment on VehicleFields {
    performance(filters: $filters) {
      value {
        active
      }
    }
  }
`

export const FACT_FRAGMENT = gql`
  fragment FactFragment on Fact {
    id
    researchCategory {
      code
      value
    }
    text
    bullets
    date
    author {
      id
      firstName
      lastName
    }
    lastUpdated
  }
`

export const OPINION_FRAGMENT = gql`
  fragment OpinionFragment on Opinion {
    id
    status {
      code
      value
    }
    researchCategory {
      code
      value
    }
    meritsText
    meritsBullets
    considerationsText
    considerationsBullets
    date
    author {
      id
      firstName
      lastName
    }
    client {
      id
      name
    }
    lastUpdated
  }
`

export const MANAGER_NOTE_FRAGMENT = gql`
  fragment ManagerNoteFragment on ManagerNote {
    id
    author {
      id
      firstName
      lastName
    }
    createDate
    title
    body
    updatedBy {
      id
      firstName
      lastName
    }
    updateDate
    confidential
    legacy
    researchCategories {
      code
      value
    }
    managers {
      id
      name
    }
    products {
      product {
        ... on ProductFields {
          id
        }
      }
    }
    glidePathVersions {
      id
    }
  }
`
