import _ from 'lodash'
import { ClientPortfolioBenchmarkFragment, ClientPortfolioDefinitionContinuedQuery, ClientPortfolioDefinitionQuery, ClientPortfolioTargetFragment, CompositeMember, CustomCharacteristicsSourceFragment, FootnoteDetailFragment, Maybe, Scalars, TargetConstantRebalance, TargetConstantRebalanceFrequencyCode, TargetConstantRebalanceInput, TargetConstantRebalanceInputItem, TargetDefinitionFragment, TargetDefinitionInput, TargetDefinitionInputItem, TargetDetailFragment, TargetMemberFragment, TargetMemberTypeCode, TargetOverviewInput, UpdateTargetFields, UpdateTargetInput, UseTypeCode, Vehicle } from '../../../__generated__/graphql'
import { convertLookupToString, excludePropertyArray } from '../../../helpers/object'
import { FootnoteModifications } from './Benchmark/TargetFootnotes'
import { CompositeMembersTableProps } from './ClientPortfolioCompositeMembers'

export type ClientPortfolioDefinitionQueryUnionType = ClientPortfolioDefinitionQuery & ClientPortfolioDefinitionContinuedQuery
export interface CompositeMemberExtendedType extends CompositeMember {
  editedEndDate?: Maybe<Scalars['Date']>
  isNewAdded?: boolean
  source?: CompositeMembersTableProps["key"]
  orderedPid?: number
}

export type TraceableTableKey = "new" | "current" | "previous"
export interface ClientPortfolioTargetFootnoteExtendType extends FootnoteDetailFragment {
  source?: TraceableTableKey
  editedValidUntilDate?: Maybe<Scalars['Date']>
}

export interface TargetConstantRebalanceExtendedType extends TargetConstantRebalance {
  source?: TraceableTableKey
}

export interface TargetDetailExtendedType extends TargetDetailFragment {
  editedName?: string
  newAdded?: boolean
  footNotes?: Maybe<ClientPortfolioTargetFootnoteExtendType>[] | undefined
  definition?: TargetDefinitionExtendedType[]
}

export interface ClientPortfolioTargetExtendedType extends ClientPortfolioTargetFragment {
  target?: TargetDetailExtendedType,
  __typename: 'ClientPortfolioTarget',
  order?: Maybe<number>
  editedOrder?: Maybe<number>
  footnoteModification: FootnoteModifications
}

export interface TargetDefinitionExtendedType extends TargetDefinitionFragment {
  source?: TraceableTableKey
  isNewAdded?: boolean
  editedEndDate?: Maybe<Scalars['Date']>
}

export interface ClientPortfolioBenchmarkExtendedType extends ClientPortfolioBenchmarkFragment {
  performanceTargetMap: ClientPortfolioTargetExtendedType[] | null | undefined
}

type Default_Target_Fragment = Omit<TargetDetailFragment, "targetId" | "__typename"> & {definition?: TargetDefinitionExtendedType[]}

const DEFAULT_NEW_START_DATE = "1900-01-01"

export const DEFAULT_TARGET_DETAIL: Default_Target_Fragment = {
  lagMonths:0,
  constantRebalance: [{
    startDate: DEFAULT_NEW_START_DATE,
    frequency: {code: TargetConstantRebalanceFrequencyCode.MTH, __typename: "TargetConstantRebalanceFrequencyLookup"},
    constant: {
      amount: 0,
      basis: 1,
      __typename: "TargetConstantRebalanceConstant",
    },
    __typename: "TargetConstantRebalance",
  }],
  useType: {code: UseTypeCode.CUSTOM, value: "Custom", __typename: "UseTypeLookup"},
  definition: [],
}

export interface ClientPortfolioHistoryData {
  headers: string[]
  rows: {date: string, data: any[]}[]
}

export const TypeNameToMemberTypeMapping: {[typename: string]: TargetMemberTypeCode} = {
  "Vehicle": TargetMemberTypeCode.F,
  "ClientPortfolio": TargetMemberTypeCode.P,
  "Target": TargetMemberTypeCode.T,
  "Index": TargetMemberTypeCode.X,
  "Group": TargetMemberTypeCode.G,
}

interface TargetDefinitionInputExtendedType extends TargetDefinitionInput {
  memberTargets?: UpdateTargetInput[]
}

export interface CustomCharacteristicsSourceExtendedType extends CustomCharacteristicsSourceFragment{
  source?: TraceableTableKey
  editedEndDate?: Maybe<Scalars['Date']>
  isNewAdded?: boolean
}

export const formatTargetDefinition = (data: TargetDefinitionExtendedType) => {
  if(data.source) {
    return data
  }
  let source = data?.endDate === "9999-12-31" ? "current": "previous"
  return {...data, source} as TargetDefinitionExtendedType
}

export const formatTargetMembers = (members: TargetDefinitionExtendedType[]) => {
  return _.orderBy(members, ["endDate", "startDate"], ["desc", "desc"]).map(el => {
    let editedEndDate = el?.editedEndDate || el?.endDate
    if(el?.memberType?.code === "T") {
      let {constantRebalance, definition} = el.member as TargetDetailExtendedType
      let orderedConstantRebalance = _.orderBy(constantRebalance || [], ["startDate"], ["desc"])[0]
      let formattedDefinition: TargetDefinitionExtendedType[] | null = definition ? ((definition || []) as TargetDefinitionExtendedType[]).map(definition => formatTargetDefinition(definition)) : null
      let orderedDefinition = formattedDefinition ? _.orderBy(formattedDefinition, ["endDate", "startDate"], ["desc", "desc"]) : null
      return {...el, editedEndDate, member: {...el?.member, constantRebalance: [orderedConstantRebalance], definition: orderedDefinition} as TargetDetailExtendedType} as TargetDefinitionExtendedType
    }else {
      return {...el, editedEndDate} as TargetDefinitionExtendedType
    }
  }) || []
}

export const isTargetUpdated = (props:{oldState: Maybe<TargetDetailExtendedType> | undefined, newState: Maybe<TargetDetailExtendedType> | undefined}) => {
  let {oldState, newState} = props
  if(newState?.useType?.code === "GENRIC") {
    return !!newState?.name && newState.name !== oldState?.name
  }else {
    return JSON.stringify(oldState) !== JSON.stringify(newState)
  }
}

const getTargetOverviewInput = (props: {oldState?: TargetDetailExtendedType, newState: TargetDetailExtendedType}) => {
  let {oldState, newState } = props
  let {name, useType, lagMonths} = newState
  let useTypeCode = useType?.code
  let result: TargetOverviewInput = {}
  let resultIsEmpty = true
  if(name !== oldState?.name) {
    result.name = name
    resultIsEmpty = false
  }
  if(useTypeCode !== oldState?.useType?.code) {
    result.useType = useTypeCode
    resultIsEmpty = false
  }
  if(lagMonths !== oldState?.lagMonths) {
    result.lagMonths = lagMonths
    resultIsEmpty = false
  }
  if(resultIsEmpty) {
    return undefined
  }
  return result
}

const formatConstantRebalanceToInput = (data: TargetConstantRebalance) => {
  let {startDate, frequency, constant} = data
  let frequencyCode = frequency?.code
  if(constant) {
    let formattedConstant = constant? {amount: constant.amount, basis: constant.basis} : null
    return {startDate, frequency: frequencyCode, constant:formattedConstant} as TargetConstantRebalanceInputItem
  }
  return null
}

const isConstantRebalanceUpdatedComparator = (x:TargetConstantRebalance, y: TargetConstantRebalance) => {
  return x?.startDate === y?.startDate && (x?.constant?.amount !== y?.constant?.amount || x?.constant?.basis !== y?.constant?.basis || x?.frequency?.code !== y?.frequency?.code)
}

const getConstantRebalanceInput = (props: {oldState?: TargetDetailExtendedType, newState: TargetDetailExtendedType}) => {
  let {oldState, newState } = props
  let result: TargetConstantRebalanceInput = {}

  let oldCRs: TargetConstantRebalance[] = _.compact(oldState?.constantRebalance) || []
  let newCRs: TargetConstantRebalance[]  = _.compact(newState?.constantRebalance) || []

  // 1. startDate in old & new, constant/frequency updated
  let updatedCRs = newCRs?.filter(newCR => oldCRs?.some(oldCR => isConstantRebalanceUpdatedComparator(oldCR, newCR)))
  // 2. startDate in new only, it's new insert
  let brandNewCRs = newCRs?.filter(newCR => !oldCRs?.some(oldCR => oldCR?.startDate === newCR?.startDate))

  let add: TargetConstantRebalanceInputItem[] = _.compact([...updatedCRs, ...brandNewCRs].map(formatConstantRebalanceToInput))

  let remove: Date[] = oldCRs?.filter(oldCR => !newCRs?.some(newCR => newCR?.startDate === oldCR?.startDate)).map(el => el?.startDate)

  let resultIsEmpty = true
  if(add.length> 0) {
    result.add = add
    resultIsEmpty = false
  }
  if(remove.length > 0) {
    result.remove = _.compact(remove)
    resultIsEmpty = false
  }

  if(resultIsEmpty) {
    return undefined
  }
  return result
}

const isTargetDefinitionBasicInfoSame = (x:TargetDefinitionExtendedType, y: TargetDefinitionExtendedType) => {
  let basicCompare =  x?.startDate === y?.startDate && (x?.assetClass?.code.toString() === y?.assetClass?.code.toString() && x?.memberType?.code === y?.memberType?.code)

  if(!basicCompare) {
    return false
  }
  if(x?.member?.__typename === "Index") {
    return (x.member as any)?.indexId.toString() === (y.member as any)?.indexId.toString()
  }else if(x?.memberType?.code === "F") {
    return (x?.member as any)?.vehicle.fundid === (y?.member as any)?.vehicle.fundid
  }else if(x?.memberType?.code === "P") {
    return (x?.member as any)?.id.toString() === (y?.member as any)?.id.toString()
  }else if(x?.memberType?.code === "T") {
    // notice if target, needs to check the member def of it.
    return (x?.member as any)?.targetId.toString() === (y?.member as any)?.targetId.toString()
  }else if(x?.member?.__typename === "Group") {
    return (x?.member as any)?.groupId.toString() === (y?.member as any)?.groupId.toString()
  }else {
    // console.log("exception", {x: x?.member, y: y?.member})
  }
  return basicCompare
}

// compare only definition value of all weights, endDate, assetClass.id, when member is the same.
const isTargetDefinitionUpdatedComparator = (x:TargetDefinitionExtendedType, y: TargetDefinitionExtendedType) => {
  let basicCompare = isTargetDefinitionBasicInfoSame(x, y)
  let valueIsChanged = (x?.minWeight !== y?.minWeight) || (x?.weight !== y?.weight) || (x?.maxWeight !== y?.maxWeight) || (x?.editedEndDate !== y?.editedEndDate)
  return basicCompare && x?.assetClass?.id === y?.assetClass?.id && valueIsChanged
}

const getTargetDefinitionInput = (props: {oldState: TargetDetailExtendedType, newState: TargetDetailExtendedType}, lazyFetchedTargetData?: TargetDetailExtendedType[] | null | undefined | Maybe<TargetDetailExtendedType>[]) => {
  // !important notice the member of targetMember is compared too.

  let {oldState, newState } = props
  // fetched data for root target
  // let fetchedData = lazyFetchedTargetData? lazyFetchedTargetData?.find(target => target?.targetId?.toString() === props.newState?.targetId.toString())?.definition : null

  let result: TargetDefinitionInputExtendedType = {}

  let oldDefs: TargetDefinitionExtendedType[] = _.compact([...(oldState?.definition || [])])
  let newDefs: TargetDefinitionExtendedType[] = _.compact(newState?.definition) || []

  // 1. startDate in old & new, constant/frequency updated
  let updatedDefs = newDefs?.filter(newDef => oldDefs?.some(oldDef => isTargetDefinitionUpdatedComparator(oldDef, newDef)))
  // 2. startDate in new only, it's new insert
  let brandNewDefs = newDefs?.filter(newDef => !oldDefs?.some(oldDef => isTargetDefinitionBasicInfoSame(oldDef, newDef)))
  // notice the member of targetMember is compared too.
  let memberTargetUpdate: UpdateTargetInput[] = []

  newDefs?.map(newDef => {
    let matchedOldDef = oldDefs?.find(oldDef => oldDef?.member?.__typename === "Target" && isTargetDefinitionBasicInfoSame(oldDef, newDef) && (JSON.stringify(oldDef) !== JSON.stringify(newDef)))
    if (matchedOldDef) {
      let oldMemberTargetState = matchedOldDef.member as TargetMemberFragment & TargetDetailExtendedType
      let newMemberTargetState = newDef.member as TargetMemberFragment & TargetDetailExtendedType
      // let fetchedMemberData = lazyFetchedTargetData? lazyFetchedTargetData?.find(target => target?.targetId?.toString() ===  newMemberTargetState?.targetId.toString())?.definition : null
      let oldMemberTargetDefs: TargetDefinitionExtendedType[]  = _.compact(oldMemberTargetState?.definition) || []
      let newMemberTargetDefs: TargetDefinitionExtendedType[]  = _.compact(newMemberTargetState?.definition) || []

      // 1. startDate in old & new, constant/frequency updated
      let updatedMemberDefs = newMemberTargetDefs?.filter(newDef => oldMemberTargetDefs?.some(oldDef => isTargetDefinitionUpdatedComparator(oldDef, newDef)))
      // 2. startDate in new only, it's new insert
      let brandNewMemberDefs = newMemberTargetDefs?.filter(newDef => !oldMemberTargetDefs?.some(oldDef => isTargetDefinitionBasicInfoSame(oldDef, newDef)))
      let memberBefore = [...updatedMemberDefs, ...brandNewMemberDefs]
      let memberAdd: TargetDefinitionInputItem[] = memberBefore.map(el => {
        let {startDate, assetClass, member, memberType} = el
        let {editedEndDate, minWeight, weight, maxWeight} = el
        let unifiedMember = member as any
        let code = (assetClass?.code || 0 )as any
        let memberId = unifiedMember?.id || unifiedMember?.indexId || unifiedMember?.vehicle?.id || unifiedMember?.groupId || unifiedMember?.targetId
        let memberTypeCode = memberType?.code
        return {startDate, assetClass: parseInt(code), member: (memberId)?.toString(), memberType: memberTypeCode || TargetMemberTypeCode.X, endDate: editedEndDate, minWeight, weight, maxWeight}
      })
      let memberRemove: TargetDefinitionInputItem[] = oldMemberTargetDefs?.filter(oldDef => !newMemberTargetDefs?.some(newDef => isTargetDefinitionBasicInfoSame(oldDef, newDef))).map(el => {
        let {startDate, assetClass, member, memberType} = el
        let unifiedMember = member as any
        let code = (assetClass?.code || 0 )as any
        // for now, support target, index, vehicle(fund) only.
        // console.log({unifiedMember})
        let memberId = unifiedMember?.id || unifiedMember?.indexId || unifiedMember?.vehicle.id || unifiedMember?.groupId || unifiedMember?.targetId
        let memberTypeCode = memberType?.code
        return {startDate, assetClass: parseInt(code), member: memberId.toString(), memberType: memberTypeCode || TargetMemberTypeCode.X}
      })

      let added = _.compact(memberAdd)
      let removed = _.compact(memberRemove)
      let definition: any = {}
      if(!added?.length && !removed?.length ) {
        definition = undefined
      }else {
        definition = {add: added.length? added: undefined, remove: removed.length? removed: undefined}
      }

      let props = {oldState: oldMemberTargetState, newState: newMemberTargetState}
      let memberResult: UpdateTargetFields = {
        overview: getTargetOverviewInput(props),
        constantRebalance: getConstantRebalanceInput(props),
        definition,
      }
      // update only when there's change
      if(memberResult?.overview || memberResult?.constantRebalance || memberResult?.definition) {
        let input = {
          id: props.newState.targetId,
          patch: memberResult
        }
        memberTargetUpdate.push(input)
      }

    } else if(newDef?.member?.__typename === "Target") {
      // // TODO no matching old so must be new add
      let newMemberTargetState = newDef.member as TargetMemberFragment & TargetDetailExtendedType
      let newMemberTargetDefs: TargetDefinitionExtendedType[]  = _.compact(newMemberTargetState?.definition) || []

      let memberAdd: TargetDefinitionInputItem[] = newMemberTargetDefs.map(el => {
        let {startDate, assetClass, member, memberType} = el
        let {editedEndDate, minWeight, weight, maxWeight} = el
        let unifiedMember = member as any
        let code = (assetClass?.code || 0 )as any
        let memberId = unifiedMember?.id || unifiedMember?.indexId || unifiedMember?.vehicle?.id || unifiedMember?.groupId || unifiedMember?.targetId
        let memberTypeCode = memberType?.code
        return {startDate, assetClass: parseInt(code), member: (memberId)?.toString(), memberType: memberTypeCode || TargetMemberTypeCode.X, endDate: editedEndDate, minWeight, weight, maxWeight}
      })

      let added = _.compact(memberAdd)
      let definition: any = {}
      if(!added?.length) {
        definition = undefined
      }else {
        definition = {add: added.length? added: undefined}
      }

      let props = {newState: newMemberTargetState}
      let memberResult: UpdateTargetFields = {
        overview: getTargetOverviewInput(props),
        constantRebalance: getConstantRebalanceInput(props),
        definition,
      }
      // update only when there's change
      if(memberResult?.overview || memberResult?.constantRebalance || memberResult?.definition) {
        let input = {
          id: props.newState.targetId,
          patch: memberResult
        }
        memberTargetUpdate.push(input)
      }
      return null
    }
  })

  let updatedMemberTargets = _.compact(memberTargetUpdate)

  // remove those null member
  let before = [...updatedDefs, ...brandNewDefs]?.filter(el => !!el?.member)
  let add: TargetDefinitionInputItem[] = before.map((el, idx) => {
    let {startDate, assetClass, member, memberType} = el
    let {editedEndDate, minWeight, weight, maxWeight} = el
    let unifiedMember = member as any
    let code = (assetClass?.code || 0 )as any
    let memberId = unifiedMember?.id || unifiedMember?.indexId || unifiedMember?.vehicle?.id || unifiedMember?.groupId || unifiedMember?.targetId
    let memberTypeCode = memberType?.code
    if(!memberId) {
      console.log(331, "invalid member ID", {before, el, idx, unifiedMember})
    }
    return {startDate, assetClass: parseInt(code), member: memberId?.toString(), memberType: memberTypeCode || TargetMemberTypeCode.X, endDate: editedEndDate, minWeight, weight, maxWeight}
  })

  let remove: TargetDefinitionInputItem[] = oldDefs?.filter(oldDef => !newDefs?.some(newDef => isTargetDefinitionBasicInfoSame(oldDef, newDef))).map(el => {
    let {startDate, assetClass, member, memberType} = el
    let unifiedMember = member as any
    let code = (assetClass?.code || 0 )as any
    let memberId = unifiedMember?.id || unifiedMember?.indexId || unifiedMember?.vehicle?.id || unifiedMember?.groupId || unifiedMember?.targetId
    let memberTypeCode = memberType?.code
    return {startDate, assetClass: parseInt(code), member: memberId.toString(), memberType: memberTypeCode || TargetMemberTypeCode.X}
  })

  if(updatedMemberTargets.length > 0) {
    result.memberTargets = _.compact(updatedMemberTargets)
  }

  if(add.length > 0) {
    result.add = _.compact(add)
  }

  if(remove.length > 0) {
    result.remove = _.compact(remove)
  }
  return result
}

export const formatTargetToUpdateTargetInput = (props: {oldState: TargetDetailExtendedType, newState: TargetDetailExtendedType}, lazyFetchedTargetData: TargetDetailExtendedType[]| null | undefined | Maybe<TargetDetailExtendedType>[]) => {
  // add editedName in. do not compare footnotes.
  let fetchedTargetData = lazyFetchedTargetData? lazyFetchedTargetData?.find(target => target?.targetId?.toString() === props.newState?.targetId.toString()) : null
  let {add, remove, memberTargets} = getTargetDefinitionInput(props, lazyFetchedTargetData)
  let definition = !add && !remove ? undefined: {add, remove}
  // console.log(358, {fetchedTargetData, lazyFetchedTargetData, add, remove, memberTargets, definition})
  let result: UpdateTargetFields = {
    overview: getTargetOverviewInput(props),
    constantRebalance: getConstantRebalanceInput(props),
    definition
  }
  let input: UpdateTargetInput| undefined = {
    id: props.newState.targetId,
    patch: result
  }

  if (!result?.overview && !result.constantRebalance && !result.definition) {
    input = undefined
  }

  return ({
    input,
    memberTargets // to update nested targetMember definition.
  })
}

export const formatTargetToCreateTargetInput = (props: any) => {
  // _.set(newState, "memberType",)
  let formattedData = convertLookupToString(props, false, [
    "TargetMemberTypeLookup",
  ])
  _.unset(formattedData, "members")
  let definition = formattedData.definition.map((el:any) => {
    let {name, assetClass, source, newAdded, member, ...rest} = el
    console.log({el, assetClass, member: member?.id})
    return {member: member?.id, assetClass: assetClass?.parent?.code || 1, ...rest}
  })
  let {name, lagMonths} = formattedData
  let constantRebalance = formattedData.constantRebalance ? formattedData.constantRebalance[0] : undefined
  let overview = {name, lagMonths}
  let result = {constantRebalance, overview, definition}
  let updateData = excludePropertyArray(result, ["__typename", "memberId"])
  return updateData
}

/**  To see if two come from same initial custom characteristics element
  ** compare startDate/memberType/memberId
**/
export const customCharacteristicsBasicInfoCompareFunction = (a:CustomCharacteristicsSourceExtendedType, b: CustomCharacteristicsSourceExtendedType) => {
  if(a?.startDate !== b?.startDate || a?.memberType?.code !== b?.memberType?.code || a?.memberType?.code !== "F") {
    return false
  }
  let memberIdFromA = ((a?.member as Vehicle)?.vehicle as Maybe<{ __typename: 'VehicleFields', name?: Maybe<string>, id: string }>)?.id
  let memberIdFromB = ((a?.member as Vehicle)?.vehicle as Maybe<{ __typename: 'VehicleFields', name?: Maybe<string>, id: string }>)?.id
  return memberIdFromA === memberIdFromB
}

// to override the default f=> fund
export const UseMemberTypeOptions = [
  {
    code: "F",
    value: "Vehicle",
  },
  {
    code: "G",
    value: "Group",
  },
  {
    code: "P",
    value: "Portfolio",
  },
  {
    code: "T",
    value: "Benchmark",
  },
  {
    code: "X",
    value: "Index",
  },
]